import { Pipe, PipeTransform } from '@angular/core';
import { Match } from '../match.model';
import { ScoreStripEntry } from '../score-strip-entry.model';
import { IsPreMatchOnlyPipe } from './is-pre-match-only.pipe';

@Pipe({
  name: 'isOver',
  pure: false,
})
export class IsOverPipe implements PipeTransform {

  constructor(
    private isPreMatchOnlyPipe: IsPreMatchOnlyPipe
  ) { }

  transform(match: Match | ScoreStripEntry): boolean {
    return this && match.LiveData 
			&& (match.LiveData.status === "completed" || match.LiveData.status === "ended" || match.LiveData.status === "retirement")
			&& !this.isPreMatchOnlyPipe.transform(match);
  }

}
