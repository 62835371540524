import { Injectable } from "@angular/core";
import { AuthHelperService } from "./auth-helper.service";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class ShortlistService {

    private shortlistLoginTriggerSource = new Subject<void>();
    shortlistLoginTriggered$ = this.shortlistLoginTriggerSource.asObservable();
    
	constructor(
		private authHelper: AuthHelperService,
		private http: HttpClient,
	) {}

	matchInShortlist(matchid: string): boolean {
		return this.authHelper.userShortlist.includes(matchid);
	}

    triggerPopup() {
        this.shortlistLoginTriggerSource.next();
    }

    toggleMatchInShortlist = (matchid: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
          if (!this.authHelper.userRetrieved || !this.authHelper.user) {
            this.triggerPopup();
            resolve(false);
          } else {
            const shortlistIndex = this.authHelper.userShortlist.indexOf(matchid);
            if (shortlistIndex > -1) {
              this.authHelper.userShortlist.splice(shortlistIndex, 1);
            } else {
              this.authHelper.userShortlist.push(matchid);
            }
            console.log(matchid);
            this.http.patch(encodeURI(`https://auth.dimers.com/api/v2/users/${this.authHelper.user.sub}`), {
              "user_metadata": {
                "match_shortlist": this.authHelper.userShortlist,
              }
            }).subscribe(
              () => resolve(true)
            );
          }
        });
      }

}
