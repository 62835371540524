import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-faq-container',
  templateUrl: './faq-container.component.html',
  styleUrls: ['./faq-container.component.scss']
})
export class FaqContainerComponent implements OnInit {

  @Input() headerText: string;
  @Input() bodyHTML: string;
  safeBody: SafeHtml;

  open = false;

  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  constructor(
    private sanitiser: DomSanitizer
  ) { }

  toggleOpen(): void {
    this.open = !this.open;
  }

  ngOnInit(): void {
    this.safeBody = this.sanitiser.bypassSecurityTrustHtml(this.bodyHTML);
  }

}
