<div *ngIf="!this.loading && !this.loadingError">
    <div>
        <section class="hero-section -mt-5 text-white">
            <div
                class="w-full max-w-[1270px] mx-auto px-0 md:px-[10px] flex flex-col md:flex-row gap-4 pt-4 sm:pt-8 justify-center ">
                <div class="basis-1/2 md:flex flex-col justify-center gap-6">
                    <h1
                        class="text-2xl md:text-3xl lg:text-4xl font-bold text-neutral-100 text-left">
                        <span>{{ this.content.page_title }}</span>
                    </h1>
                    <div *ngIf="!offerLoading && !offerLoadingError && featureOffer" class="description text-base font-normal hidden md:block">
                        {{ content.overview }}
                    </div>

                    <div *ngIf="!offerLoading && !offerLoadingError && !featureOffer">
                        <div>
                            {{ content?.no_offer_text}}
                        </div>
                        <button class="w-full max-w-[200px] bg-highlight-100 h-10 rounded shadow-md text-sm font-semibold mt-4" (click)="this.jumpToSection('compare-promo-with-other-sportsbooks')"> 
                            Explore other providers
                        </button>
                    </div>
                    <div class="hidden md:block">
                        <a href="/best-books/methodology" routerLink="/best-books/methodology" class="flex flex-row gap-2 items-center text-base sm:text-xl font-semibold text-white hover:text-highlight-100">
                            How do Dimers' ratings work?
                            <fa-icon class="sm:flex items-center hidden " [icon]="faArrowUpRight"></fa-icon>
                        </a>
                    </div>
                </div>

                <div class="sportsbook-table w-full md:basis-1/2 flex justify-center">
                    <div *ngIf="!offerLoading && !offerLoadingError"
                        class="w-full max-w-[309px] flex flex-col bg-white rounded-lg overflow-hidden text-black h-fit">
                        <div class="px-4 flex items-center justify-between h-16"
                            [style.background-color]="content.sportsbook.metadata.hex_color">
                            <img [src]="content.sportsbook.metadata.dark_bg_logo.imgix_url + '?auto=format,compress&h=40'" alt="logo"
                                class="h-10 w-[108px]" loading="lazy">
                            <div class="flex flex-row gap-2 items-center">
                                <fa-icon class="text-amber-400 text-sm" [icon]="faStarFull"></fa-icon>
                                <div class="text-white font-semibold">
                                    {{ content.sportsbook.metadata.rating.overall_score}}
                                </div>
                            </div>
                        </div>
                        <div class="p-4 flex flex-col gap-3">
                            <div class="text-sm uppercase text-highlight-100 font-bold">
                                Best for {{ content.sportsbook.metadata.best_for}}
                            </div>

                            <div class="bg-gray-100  p-3 rounded flex flex-col gap-3" *ngIf="this.featureOffer">
                                <div class="flex flex-col gap-2 offer-content">
                                    <div class="flex items-center justify-center gap-2 flex-wrap"
                                        *ngIf="featureOffer?.metadata?.tags">
                                        <ng-container *ngFor="let tag of featureOffer?.metadata?.tags">
                                            <div class="rounded-xl px-2 uppercase text-[12px] font-medium"
                                                [style.background-color]="tag.metadata.bg_hex_color"
                                                [style.color]="tag.metadata.text_hex_color">
                                                {{ tag.metadata.text}}
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="text-highlight-100 text-xl font-bold text-center header">
                                        {{ featureOffer.metadata.promos_title }}
                                    </div>
                                </div>
                            </div>


                            <div class="flex gap-2" *ngIf="this.featureOffer">
                                <a class="best-books-button flex-1" (click)="redirectBookiePromoLink(
                                    featureOffer.metadata.promos_link,
                                    {src: 'bookies'},
                                    featureOffer.metadata.sportsbook[0]
                                )"> Sign up </a>
                                <div class="h-10 w-10 border rounded flex items-center justify-center hover:border-highlight-100"
                                    [class.border-highlight-100]="offerDropdownOpen"
                                    (click)="toggleOfferDropdown()">
                                    <fa-icon class="text-xs text-black" *ngIf="offerDropdownOpen"
                                        [icon]="faChevronUp"></fa-icon>
                                    <fa-icon class="text-xs text-black" *ngIf="!offerDropdownOpen"
                                        [icon]="faChevronDown"></fa-icon>
                                </div>
                            </div>

                            <div class="flex flex-col gap-3" *ngIf="offerDropdownOpen || !featureOffer">
                                <div class="text-black text-xs font-medium"
                                    [innerHTML]="featureOffer.metadata.promos_disclaimer | trustCosmic"
                                    *ngIf="this.featureOffer">
                                </div>

                                <div
                                    class="divide-y divide-gray-200 border-t border-b border-gray-200 font-medium font-base text-neutral-500 w-full">
                                    <div class="py-2 flex flex-row justify-between">
                                        <div class="text-sm">
                                            Betting Features
                                        </div>
                                        <div class="flex flex-row gap-1 items-center min-w-[50px]">
                                            <fa-icon class="text-amber-400 text-sm" [icon]="faStarFull">
                                            </fa-icon>
                                            <div class="font-medium text-sm text-black">
                                                {{content.sportsbook.metadata.rating.betting_features }}
                                                <span class="font-medium text-sm text-neutral-400">
                                                    /5.0
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="py-2 flex flex-row justify-between">
                                        <div class="text-sm">
                                            The Financials
                                        </div>
                                        <div class="flex flex-row gap-1 items-center min-w-[50px]">
                                            <fa-icon class="text-amber-400 text-sm" [icon]="faStarFull">
                                            </fa-icon>
                                            <div class="font-medium text-sm text-black">
                                                {{ content.sportsbook.metadata.rating.money_rewards }}
                                                <span class="font-medium text-sm text-neutral-400">
                                                    /5.0
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="py-2 flex flex-row justify-between">
                                        <div class="text-sm">
                                            User Experience
                                        </div>
                                        <div class="flex flex-row gap-1 items-center min-w-[50px]">
                                            <fa-icon class="text-amber-400 text-sm" [icon]="faStarFull">
                                            </fa-icon>
                                            <div class="font-medium text-sm text-black">
                                                {{ content.sportsbook.metadata.rating.user_experience }}
                                                <span class="font-medium text-sm text-neutral-400">
                                                    /5.0
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="py-2 flex flex-row justify-between">
                                        <div class="text-sm">
                                            Customer Support
                                        </div>
                                        <div class="flex flex-row gap-1 items-center min-w-[50px]">
                                            <fa-icon class="text-amber-400 text-sm" [icon]="faStarFull">
                                            </fa-icon>
                                            <div class="font-medium text-sm text-black">
                                                {{ content.sportsbook.metadata.rating.customer_support }}
                                                <span class="font-medium text-sm text-neutral-400">
                                                    /5.0
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-center items-center min-h-[255px]" *ngIf="offerLoading">
                        <div role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                
                    <div class="flex justify-center items-center min-h-[255px]" *ngIf="offerLoadingError"></div>
                </div>
            </div>
            <div class="md:hidden">
                <a href="/best-books/methodology" routerLink="/best-books/methodology" class="flex flex-row gap-2 items-center text-base sm:text-xl font-semibold text-white hover:text-highlight-100">
                    How do Dimers' ratings work?
                    <fa-icon class="sm:flex items-center hidden " [icon]="faArrowUpRight"></fa-icon>
                </a>
            </div>
        </section>

        <section class="reviewer-info mt-4 sm:mt-8 bg-navy-100 px-6 py-4 rounded-lg"
            *ngIf="this.content?.author_object && this.content?.review_time">
            <app-reviewer-info [author]="this.content?.author_object"
                [reviewTime]="this.content?.review_time"></app-reviewer-info>
        </section>
    </div>

    <div class="flex gap-8 mt-8">
        <div class="hidden lg:block sticky h-screen top-3 w-full max-w-xs">
            <div class="max-h-screen overflow-y-auto pb-6">
                <ul class="text-gray-600 list-none m-0" role="list">
                    <li class="">
                        <div class="flex items-baseline gap-2 py-2 px-4 text-highlight-100 bg-gray-100 rounded cursor-pointer"
                            (click)="toggleOpen(0)">
                            <fa-icon *ngIf="this.navigationMenu[0]" class="flex self-end" [icon]="faSortUp"></fa-icon>
                            <fa-icon *ngIf="!this.navigationMenu[0]" class="flex" [icon]="faSortDown"></fa-icon>
                            <div>In This Guide</div>
                        </div>
                        <ul role="list" class="list-none m-0 pl-4" *ngIf="this.navigationMenu[0]">
                            <ng-container *ngIf="content.sections">
                                <li *ngFor="let item of content.sections"
                                    (click)="this.jumpToSection(item.title)"
                                    class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium">
                                    {{ item.title }}
                                </li>
                            </ng-container>
                        </ul>
                    </li>
                    <li class="mt-1">
                        <div class="flex items-baseline gap-2 py-2 px-4 text-highlight-100 bg-gray-100 rounded  cursor-pointer"
                            (click)="toggleOpen(1)">
                            <fa-icon *ngIf="this.navigationMenu[1]" class="flex self-end" [icon]="faSortUp"></fa-icon>
                            <fa-icon *ngIf="!this.navigationMenu[1]" class="flex" [icon]="faSortDown"></fa-icon>
                            <div>Dimers' Experts</div>
                        </div>
                        <ul role="list" class="list-none m-0 pl-4" *ngIf="this.navigationMenu[1]">
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize"
                                (click)="this.jumpToSection('trust-dimers-experts')">Why You Can Trust Dimers' Experts
                            </li>
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium"
                            *ngIf="this.content?.faq" (click)="this.jumpToSection('faq')">
                            FAQ
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

        </div>
        <div class="max-w-3xl w-full lg:w-[72%] flex-1 lg:ml-4 flex flex-col gap-6 md:gap-12 mb-10">

            <ng-container *ngFor="let data of content.sections; let i = index">
                <section [id]="data.title">
                    <h2>
                        {{ data.title }}
                    </h2>
                    <div class="text" [innerHTML]="data.content | trustCosmic">
                    </div>
                </section>
                <section *ngIf="i===1 && !offerLoading && !offerLoadingError && featureOffer">
                    <app-book-offer-banner [promo]="featureOffer"  pageLocation="sportsbook-promo-code-in-content" [stateCode]="this.stateCode"></app-book-offer-banner>
                </section>
            </ng-container>

            <section class="compare-promo-with-other-sportsbooks" id="compare-promo-with-other-sportsbooks"
                *ngIf="content.compare_promo_with_other_sportsbooks?.title">
                <h2>
                    {{ content.compare_promo_with_other_sportsbooks.title }}
                </h2>
                <div class="best-promos">
                    <div class="promos-tabs">
                        <swiper-container slides-per-view="auto" class="mySwiper">
                            <ng-container *ngFor="let promo of offers">
                                <swiper-slide>
                                    <div class="promos-tab" [class]="promo.metadata.sportsbook[0].slug"
                                        [class.active]="bestBookTab === promo.metadata.sportsbook[0].slug"
                                        (click)="this.toggleShow(promo.metadata.sportsbook[0].slug)">
                                        <img [src]="promo.metadata.sportsbook[0].metadata.promos_small_logo.imgix_url + '?auto=format,compress&h=36'" height="24"
                                            width="24" alt="logo">
                                    </div>
                                </swiper-slide>
                            </ng-container>
                        </swiper-container>
                    </div>
            
                    <div class="promos-items max-w-4xl">
                        <ng-container *ngFor="let promo of offers">
                            <div class="best-promos-book" [class]="promo.metadata.sportsbook[0].slug"
                                [class.active]="bestBookTab === promo.metadata.sportsbook[0].slug">
                                <app-book-offer-banner [promo]="promo" pageLocation="sportsbook-promo-code-in-content"
                                    [stateCode]="this.stateCode"></app-book-offer-banner>
                            </div>
                        </ng-container>
                    </div>
            
                    <div class="p-4 text-base font-medium text-neutral-600 bg-navy-50 rounded">
                        No sportsbook is best for everyone. What’s best for you might not be best for someone else. We hope this
                        guide can help narrow down your options, regardless of your betting style or level of experience.
                    </div>
                </div>
            
                <div class="text" 
                *ngIf="content.compar_promo_with_other_sportsbooks?.content"
                    [innerHTML]="this.content.compar_promo_with_other_sportsbooks?.content | trustCosmic">
                </div>
            </section>
            <section class="trust-dimers-experts" id="trust-dimers-experts">
                <h2>
                    {{ content.why_you_can_trust.title}}
                </h2>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-12 mt-8">
                    <div class="border border-highlight-100 rounded-lg p-6 flex flex-col gap-4 justify-end items-center relative bg-navy-50"
                        *ngFor="let item of content.why_you_can_trust.content.data">
                        <div
                            class="flex justify-center items-center w-12 h-12 text-xl text-white bg-highlight-100 border-highlight-100 rounded-md absolute top-0 -mt-[24px]">
                            <img [src]="item.icon" alt="" width="24" height="24">
                        </div>
                        <div class="text-lg text-gray-900 font-semibold mt-4">
                            {{ item.title }}
                        </div>
                        <div [innerHTML]="item.content | trustCosmic" class="text text-center">
                        </div>
                    </div>
                </div>
            </section>

            <section class="faq" id="faq" *ngIf="this.content?.faq">
                <h2>
                    FAQs
                </h2>
                <div>
                    <ng-container *ngFor="let content of this.content?.faq.data">
                        <app-faq-container [headerText]="content.question"
                            [bodyHTML]="content.answer"></app-faq-container>
                    </ng-container>
                </div>
            </section>
        </div>
    </div>

    <section class="expert-details my-8" *ngIf="this.content?.author_object">
        <app-author-section [author]="this.content?.author_object"></app-author-section>
    </section>
</div>

<div class="loading" *ngIf="this.loading && !this.loadingError">
    Loading...
</div>
<div class="loading" *ngIf="this.loadingError">
    <span>
        Sorry, something went wrong loading this page. Please <span class="retry-link" (click)="retrieveData()">try
            again</span>.
    </span>
</div>