import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faBars, faChevronLeft, faChevronDown, faSearch, faCircleUser } from '@fortawesome/pro-solid-svg-icons';
import { Observable, map, shareReplay } from 'rxjs';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { BlogService } from 'src/app/blog.service';
import { Sport } from 'src/app/sport.model';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss']
})
export class MobileNavigationComponent implements OnInit{

    sportsDropdownOpen = false;
	mobileSecondarySportMenuActive = false;
	mobileNavVisible = false;
	accountDropdownOpen = false;
	@Input() dropdownSports: Array<Sport>;
	@Input() otherSports: Array<Sport>;
	@Input() liveLightVisible: boolean;
	@Input() fireNewsletterPopUp: () => void;
	searchText = "";
	moreDropdownOpen = false;
	booksDropdownOpen = false;
    navMoreSidebar = false;
    sportsbooksSubMenu = false;
    bettingSubMenu = false;
    navSportsSidebar = false;
    sportsbookPromoSubMenu = false;
    secondaryMenu = [false, false, false, false, false, false];
    navBettingSidebar = false;
    sportsbooks = [];
	faBars = faBars;
	faSearch = faSearch;
	faChevronLeft = faChevronLeft;
	faChevronRight = faChevronRight;
	faChevronDown = faChevronDown;
	faCircleUser = faCircleUser;
    dfsProviders = [];

    showNavBtn:boolean = true;

    proUser$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
		map((detailedUser) => {
			return !!detailedUser?.app_metadata?.dimers_pro
		}),
		shareReplay(1)
	);
    
    affiliate$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
		map((detailedUser) => {
			return !!detailedUser?.app_metadata?.dimers_affiliate
		}),
		shareReplay(1)
	);
    
    states= [
        {name: 'North Carolina', img: 'https://cdn.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg', link: "/best-books/north-carolina"},
        {name: 'Vermont', img: 'https://cdn.cosmicjs.com/c9813100-115a-11ee-a61c-b352d78f91a5-Vermont.svg', link: "/best-books/vermont"},
        {name: 'Kentucky', img: 'https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=25', link: "/best-books/kentucky"},
        {name: 'Arizona', img: 'https://cdn.cosmicjs.com/ec3f30c0-1001-11ee-b1c4-fdbd98303cc6-Arizona.svg', link: "/best-books/arizona"},
        {name: 'Arizona', img: 'https://cdn.cosmicjs.com/ec3f30c0-1001-11ee-b1c4-fdbd98303cc6-Arizona.svg', link: "/best-books/arizona"},
        {name: 'Colorado', img: 'https://cdn.cosmicjs.com/ec401b20-1001-11ee-b1c4-fdbd98303cc6-Colorado.svg', link: "/best-books/colorado"},
        {name: 'Illinois', img: 'https://cdn.cosmicjs.com/ec409050-1001-11ee-b1c4-fdbd98303cc6-Illinois.svg', link: "/best-books/illinois"}, 
        {name: 'Indiana', img: 'https://cdn.cosmicjs.com/ec428c20-1001-11ee-b1c4-fdbd98303cc6-Indiana.svg', link: "/best-books/indiana"},
        {name: 'Kansas', img: 'https://cdn.cosmicjs.com/ec437680-1001-11ee-b1c4-fdbd98303cc6-Kansas.svg', link: "/best-books/kansas"}, 
        {name: 'Louisiana', img: 'https://cdn.cosmicjs.com/ec460e90-1001-11ee-b1c4-fdbd98303cc6-Louisiana.svg', link: "/best-books/louisiana"},
        {name: 'Maryland', img: 'https://cdn.cosmicjs.com/ec46d1e0-1001-11ee-b1c4-fdbd98303cc6-Maryland.svg', link: "/best-books/maryland"},
        {name: 'Massachusetts', img: 'https://cdn.cosmicjs.com/ec5d1900-1001-11ee-b1c4-fdbd98303cc6-Massachusetts.svg', link: "/best-books/massachusetts"}, 
        {name: 'Michigan', img: 'https://cdn.cosmicjs.com/ec5f3be0-1001-11ee-b1c4-fdbd98303cc6-Michigan.svg', link: "/best-books/michigan"},
        {name: 'New Jersey', img: 'https://cdn.cosmicjs.com/ec5e2a70-1001-11ee-b1c4-fdbd98303cc6-New-Jersey.svg', link: "/best-books/new-jersey"}, 
        {name: 'New York', img: 'https://cdn.cosmicjs.com/ec5fb110-1001-11ee-b1c4-fdbd98303cc6-New-York.svg', link: "/best-books/new-york"},
        {name: 'Ohio', img: 'https://cdn.cosmicjs.com/ec602640-1001-11ee-b1c4-fdbd98303cc6-Ohio.svg', link: "/best-books/ohio"},
        {name: 'Pennsylvania', img: 'https://cdn.cosmicjs.com/ec5e9fa0-1001-11ee-b1c4-fdbd98303cc6-Pennsylvania.svg', link: "/best-books/pennsylvania"},
        {name: 'Iowa', img: 'https://imgix.cosmicjs.com/d83e77a0-115c-11ee-abb6-f9903c690bdf-Iowa.svg', link: "/best-books/iowa"}
    ];

	constructor(
		@Inject(DOCUMENT) public document: Document,
		private router: Router,
		public authHelper: AuthHelperService,
		private gtmService: GoogleTagManagerService,
		private blogService: BlogService,
	) {
		// ensure that mobile navigation is closed, and both navs are reset, when user goes to a new page
			router.events.subscribe((val) => {
				if (val instanceof NavigationEnd) {
					this.mobileNavVisible = false;
					this.mobileSecondarySportMenuActive = false;
					this.searchText = "";
                    if(val.urlAfterRedirects === "/email-verify-required") {
                        this.showNavBtn = false;
                    }
				}
			})
	}

    ngOnInit(): void {
        this.getSportsbooksReview();
        this.getDFSReviewLinks();
    }
	submitSearch(): void {
		this.router.navigateByUrl("/search?q=" + this.searchText)
	}

	toggleSportsDropdown(): void {
		this.sportsDropdownOpen = !this.sportsDropdownOpen;

		this.accountDropdownOpen = false;
		this.moreDropdownOpen = false;
		this.booksDropdownOpen = false;
		this.navMoreSidebar =false;
        this.sportsbooksSubMenu = false;
        this.bettingSubMenu = false;
        
		if (!this.sportsDropdownOpen) {
			this.mobileSecondarySportMenuActive = false;
		}
	}

	// showSportsDropdown(): void {
	// 	this.sportsDropdownOpen = true;
	// }

	// hideSportsDropdown(): void {
	// 	this.sportsDropdownOpen = false;
	// 	this.mobileSecondarySportMenuActive = false;
	// }

	toggleMobileNav(): void {
		this.mobileNavVisible = !this.mobileNavVisible;
	}

	hideMobileNav(): void {
		this.mobileNavVisible = false;
	}
	
	toggleAccountDropdown(): void {
		this.accountDropdownOpen = !this.accountDropdownOpen;

		this.sportsDropdownOpen = false;
		this.moreDropdownOpen = false;
		this.booksDropdownOpen = false;
	}
	
	hideAccountDropdown(): void {
		this.accountDropdownOpen = false;
	}

	// toggleMoreDropdown(): void {
	// 	this.moreDropdownOpen = !this.moreDropdownOpen;
	// 	this.accountDropdownOpen = false;
	// 	this.sportsDropdownOpen = false;
	// 	this.booksDropdownOpen = false;
	// }

    toggleNavMoreSidebar(): void {
        this.hiddenAllSubMenus();
        this.navSportsSidebar = false;
        this.navBettingSidebar = false;
		this.accountDropdownOpen = false;
		this.navMoreSidebar = !this.navMoreSidebar;
	}

    toggleNavSportsSidebar(): void {
        this.hiddenAllSubMenus();
        this.navBettingSidebar = false;
        this.navMoreSidebar = false;
        this.navSportsSidebar = !this.navSportsSidebar;
		this.accountDropdownOpen = false;
		this.booksDropdownOpen = false;
    }

    hideNavBettingSidebar(): void {
        this.hiddenAllSubMenus();
        this.navBettingSidebar = false;
        this.navMoreSidebar = false;
        this.navSportsSidebar = false;
    }

    toggleNavBettingSidebar():void {
        this.hiddenAllSubMenus();
        this.navSportsSidebar = false;
        this.navMoreSidebar = false;
		this.accountDropdownOpen = false;
        this.navBettingSidebar = !this.navBettingSidebar;
    }

    // toggleSportsbooksSubMenu(): void {
    //     this.bettingSubMenu = false;
    //     this.sportsbooksSubMenu = !this.sportsbooksSubMenu;
    // }

    // toggleBettingsSubMenu(): void {
    //     this.sportsbooksSubMenu = false;
    //     this.bettingSubMenu = !this.bettingSubMenu;
    // }

    toggleSecondaryMenu(subMenu:number): void {
        for (let i in this.secondaryMenu) {
            if(Number(i) !== subMenu) {
                this.secondaryMenu[i] = false;
            }
         }
        this.secondaryMenu[subMenu] = !this.secondaryMenu[subMenu];
    }

    hiddenAllSubMenus(): void {
        for (let i in this.secondaryMenu) {
                this.secondaryMenu[i] = false;
            }
    }

    hideAllOpenMenuLinks(): void {
        this.hiddenAllSubMenus();
        this.navMoreSidebar = false;
        this.navSportsSidebar = false;
        this.navBettingSidebar = false;
        this.sportsDropdownOpen = false;
    }

    @HostListener('window:resize',['$event']) onResize(e: MouseEvent) {
        this.hideAllOpenMenuLinks();
	}

	hideMoreDropdown(): void {
		this.moreDropdownOpen = false;
	}

	toggleBooksDropdown(): void {
		this.booksDropdownOpen = !this.booksDropdownOpen;

		this.accountDropdownOpen = false;
		this.moreDropdownOpen = false;
		this.sportsDropdownOpen = false;
	}

	hideBooksDropdown(): void {
		this.booksDropdownOpen = false;
	}

	sendTapTag(pageName: string): void {
		const gtmTag = {
			event: "tapped",
			event_category: "Mobile Menu",
			event_label: pageName,
		};
	  
		this.gtmService.pushTag(gtmTag)
			.catch(e => {
				console.error("Google Tag Manager Script failed: " + e)
			});
	}

    getSportsbooksReview(): void {
        this.blogService.sportsBookReviewLinks$.subscribe((reviewLinks) => this.sportsbooks = reviewLinks, () => {
            this.sportsbooks = [];
        });
    }

	getDFSReviewLinks() {
        this.blogService.dfsReviewLinks$.subscribe((reviewLinks) => this.dfsProviders = reviewLinks, () => {
            this.dfsProviders = [];
        });
	}
    
    selectTab(tab: string) {
        this.blogService.setSelectedMyaccountTab(tab);
    }

    openProModal() {
        // Open Pro modal
        this.blogService.setProModalData(true);
    }
    
    // remove smartlook functionality
    // sendToSmartLook(btn:string): void {
    //     const eventName = 'mobile-navigation-click';
    //     let property = {
    //     "page-path": this.document?.location.pathname,
    //     "button":btn
    //     };
    //     if (typeof window !== "undefined") {
    //         window.smartlook('track', eventName, property);
    //     }
    // }

}
