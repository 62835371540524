import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bookBackgroundColour'
})
export class BookBackgroundColourPipe implements PipeTransform {

  transform(bookName: string): string {
    switch (bookName?.toLowerCase()) {
      case "betstreet":
        return "#1392fd";
      case "fanduel":
        return "#0078ff";
      case "sugarhouse":
        return "#286193";
      case "pointsbet":
        return "#040707";
      case "william_hill":
        return "#173432";
      case "draftkings":
        return "#5eb700";
      case "wynn":
        return "#ba8a03";
      case "betmgm":
        return "#c0a971";
      case "bet_rivers_in":
      case "bet_rivers_va":
      case "bet_rivers_co":
        return "#ffb612";
      case "bet365":
      case "bet_365":
        return "#14805e";
      case "betway":
        return "#17a730";
      case "unibet":
        return "#1c7a47";
      case "tipico":
        return "#282b30";
      case "twinspires":
        return "#248ff1";
      default:
        return "initial"
    }
  }

}
