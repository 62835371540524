<div class="promos-item shadow shadow-gray-300 rounded-lg overflow-hidden">
    <div class="short flex md:h-32">
        <div class="logo flex items-center justify-center min-w-[218px]"
            [style.background-color]="promo.metadata.sportsbook[0].metadata.hex_color">
            <img [src]="promo.metadata.sportsbook[0].metadata.dark_bg_logo.imgix_url + '?auto=format,compress&h=56'" alt="logo" class="h-14"
                loading="lazy">
        </div>
        <div class="promo-content w-full max-w-full p-5 bg-gray-100 flex flex-col justify-center">
            <div class="flex items-center gap-2 flex-wrap" *ngIf="promo.metadata.tags">
                <ng-container *ngFor="let tag of promo.metadata.tags">
                    <div class="rounded-xl px-2 uppercase text-[12px] font-medium"
                        [style.background-color]="tag.metadata.bg_hex_color"
                        [style.color]="tag.metadata.text_hex_color">
                        {{ tag.metadata.text}}
                    </div>
                </ng-container>
            </div>
            <div class="text-xl md:text-2xl font-bold text-highlight-100 mt-2 header">
                {{ promo.metadata.promos_title }}
            </div>
        </div>
    </div>
    <div class="flex justify-between items-center px-5">
        <div class="flex gap-3 items-center"  (click)="toggleOfferDropdown($event, promo.metadata.sportsbook[0].slug)">
            <div class="text-base font-medium cursor-pointer"> 
                <ng-container *ngIf="stateCode !== 'ontario'">
                    How to claim this promo
                </ng-container>
                <ng-container *ngIf="stateCode === 'ontario'">
                    Find more info
                </ng-container>
            </div>
            <div class="cursor-pointer">
                <fa-icon class="text-xs text-black" *ngIf="!offerDropdownOpen" [icon]="faChevronDown"></fa-icon>
                <fa-icon class="text-xs text-black" *ngIf="offerDropdownOpen" [icon]="faChevronUp"></fa-icon>
            </div>
        </div>
        <div class="flex gap-3 items-center">
            <div class="font-base font-medium from-highlight-100" *ngIf="promo.metadata?.sportsbook[0]?.metadata?.review">
                <a href="/best-books/reviews/{{ promo.metadata?.sportsbook[0]?.slug }}/" routerLink="/best-books/reviews/{{ promo.metadata?.sportsbook[0]?.slug }}/">
                    Read review
                </a>
            </div>
            <button class="w-[200px] claim-button"
            (click)="redirectBookiePromoLink(promo.metadata.promos_link,
                    {src: 'bestbook-content', promo: 'true'},
                    promo.metadata.sportsbook[0]
                )">
                {{ promo.metadata.promos_button_text }}
            </button>
        </div>
    </div>
    <div class="promo-steps" *ngIf="this.promo?.metadata.promos_steps && offerDropdownOpen"
        [innerHTML]="this.promo?.metadata.promos_steps | trustCosmic">
    </div>
    <div class="promo-disclaimer text-xs bg-slate-100 py-3 px-5"
        *ngIf="this.promo?.metadata.promos_disclaimer"
        [innerHTML]="this.promo?.metadata.promos_disclaimer | trustCosmic">
    </div>
</div>
<div class="promos-item-mobile rounded-lg shadow overflow-hidden flex flex-col" 
    (click)="redirectBookiePromoLink(
    promo.metadata.promos_link,
    {src: 'bestbook-content',promo: 'true'},
    promo.metadata.sportsbook[0]
)">
    <div class="px-4 flex items-center justify-center h-16" [style.background-color]="promo.metadata.sportsbook[0].metadata.hex_color">
        <img alt="logo" [src]="promo.metadata.sportsbook[0].metadata.dark_bg_logo.imgix_url + '?auto=format,compress&h=40'" class="h-10 w-[108px] w-auto" loading="lazy">
    </div>
    <div class="px-4 pt-4 flex flex-col gap-2">
        <div class="bg-gray-100  p-3 rounded flex flex-col gap-2">
            <div class="flex flex-col gap-1 offer-content">
                <div class="flex items-center justify-center gap-2 flex-wrap" *ngIf="promo?.metadata?.tags">
                    <ng-container *ngFor="let tag of promo?.metadata?.tags">
                        <div class="rounded-xl px-2 uppercase text-[12px] font-medium"
                            [style.background-color]="tag.metadata.bg_hex_color"
                            [style.color]="tag.metadata.text_hex_color">
                            {{ tag.metadata.text}}
                        </div>
                    </ng-container>
                </div>
                <div class="text-highlight-100 text-base font-bold text-center header">
                    {{ promo.metadata.promos_title }}
                </div>
                <!-- <div class="text-sm font-medium text-center leading-4 sub-header">
                    {{ promo.metadata.promos_title }}
                </div> -->
                <div class="text-xs text-slate-500 text-center">
                    T&C's apply.
                </div>
            </div>
            <button class="claim-button">
                {{ promo.metadata.promos_button_text }}
            </button>
        </div>
        <div>
            <div (click)="toggleOfferDropdown($event, promo.metadata.sportsbook[0].slug)" class="pb-2">
                <div class="flex justify-center items-center cursor-pointer">
                    <!-- <div class="text-xs text-slate-500">
                        T&C’s apply.
                    </div> -->
                    <div class="text-sm font-medium text-highlight-100" *ngIf="promo.metadata?.sportsbook[0]?.metadata?.review">
                        <a class="text-highlight-100" href="/best-books/reviews/{{ promo.metadata?.sportsbook[0]?.slug }}/" routerLink="/best-books/reviews/{{ promo.metadata?.sportsbook[0]?.slug }}/">
                            Read review
                        </a>
                    </div>
                    <div class="cursor-pointer ml-auto">
                        <ng-container *ngIf="stateCode !== 'ontario'">
                            <span class="text-sm font-medium text-highlight-100 mr-2">How to claim</span>
                        </ng-container>
                        <ng-container *ngIf="stateCode === 'ontario'">
                            <span class="text-sm font-medium text-highlight-100 mr-2">Find more info</span>
                        </ng-container>
                        <fa-icon class="text-xs text-highlight-100" *ngIf="!offerDropdownOpen" [icon]="faChevronDown"></fa-icon>
                        <fa-icon class="text-xs text-highlight-100" *ngIf="offerDropdownOpen" [icon]="faChevronUp"></fa-icon>
                    </div>
                </div>
                <div class="mt-2 flex flex-col gap-2" *ngIf="offerDropdownOpen">
                    <div class="promo-steps text-xs font-semibold leading-[18px]" *ngIf="promo?.metadata.promos_steps"
                        [innerHTML]="promo?.metadata.promos_steps | trustCosmic"></div>
                    <div class="text-xs p-4 bg-slate-100 -ml-4 -mr-4 -mb-4 disclaimer" *ngIf="offerDropdownOpen"
                    [innerHTML]="promo?.metadata.promos_disclaimer | trustCosmic">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>