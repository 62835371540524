import { Component, OnInit } from '@angular/core';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { BlogService } from 'src/app/blog.service';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-pro-modal',
  templateUrl: './pro-modal.component.html',
  styleUrls: ['./pro-modal.component.scss']
})
export class ProModalComponent implements OnInit  {

  betIntent:boolean = false;
  visible = false;
  faTimes = faTimes;
  frequencyOptions = ["weekly", "monthly", "yearly"];
  selectedFrequency = "monthly";
  faCheckCircle = faCheckCircle;

  processing:boolean = false;
  
  constructor(
    private blogService: BlogService,
    public authHelper: AuthHelperService,
    private gtmService: GoogleTagManagerService,
  ) {}

  ngOnInit(): void {
    this.blogService.proModalData$.subscribe(data => {
        this.visible = data.modalVisible;
        this.betIntent = data.betIntent;
        this.processing = !data;
    });
  }

  setVisible() {
    this.blogService.setProModalData(false);
  }
  
  redirecting() {
    this.processing = true;
  }

  setFrequency(freq: string) {
    this.selectedFrequency = freq;
    const gtmTag = {
      event: "pro_plan_toggle",
      event_category: "modal",
      event_label: freq,
    };

    this.gtmService.pushTag(gtmTag)
      .catch(e => {
        console.error("Google Tag Manager Script failed: " + e)
      });
  }
}
