<div class="about-dimers">
  <div class="heading">
    About Dimers
  </div>
  <div class="statistics grid grid-cols-1 md:grid-cols-3">
    <div class="event-number flex flex-col items-center gap-2">
      <div class="number">
        <img src="https://imgix.cosmicjs.com/f501d5b0-58e4-11ee-94c0-5f69598aa628-Events.svg?auto=format,compress&w=40" height="40" width="40" loading="lazy" alt="icon"> 22,000+
      </div>
      <div class="text">
        Events covered per year
      </div>
    </div>
    <div class="social-media-number  flex flex-col items-center gap-2">
      <div class="number">
        <img src="https://imgix.cosmicjs.com/f5009d30-58e4-11ee-94c0-5f69598aa628-SocialMedia.svg?auto=format,compress&w=40" height="40" width="40"loading="lazy" alt="icon"> 65,000+
      </div>
      <div class="text">
        Social media community
      </div>
    </div>
    <div class="user-number flex flex-col gap-2 items-center">
      <div class="number">
        <img src="https://imgix.cosmicjs.com/f4ff3da0-58e4-11ee-94c0-5f69598aa628-Users.svg?auto=format,compress&w=40" height="40" width="40" loading="lazy" alt="icon"> 8,000,000+
      </div>
      <div class="text">
        Users
      </div>
    </div>
  </div>
</div>