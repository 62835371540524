import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, Renderer2, ViewEncapsulation } from '@angular/core';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { MetaSetterService } from 'src/app/general/meta-setter.service';
import { DOCUMENT, isPlatformBrowser, ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { faStar as faStarSolid, faArrowUp, faSortDown, faSortUp, faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { faClipboardCheck, faCircleExclamation, faStar as faStarRegular } from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpRight, faThumbsUp, faThumbsDown  } from '@fortawesome/pro-light-svg-icons';

import { BlogService } from 'src/app/blog.service';
import { BetService } from 'src/app/bet.service';

@Component({
  selector: 'app-ca-sportsbooks-review',
  templateUrl: './ca-sportsbooks-review.component.html',
  styleUrls: ['./ca-sportsbooks-review.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CaSportsbooksReviewComponent implements OnInit, AfterViewInit {

    navTab = "";
    browserMode = false;
    windowScrolled = false;
    bookmaker: string = '';
    stateCode: string = "";
    state:string = "";
    featureOffer: any;
    sportsbook: string = "";
    sportsbookPromo:string = "";

    faCircleExclamation = faCircleExclamation;
    faClipboardCheck = faClipboardCheck;
    faStarFull = faStarSolid;
    faArrowUp = faArrowUp;
    faSortDown = faSortDown;
    faSortUp = faSortUp;
    faArrowUpRight = faArrowUpRight;

    faChevronDown = faChevronDown;
    faChevronUp = faChevronUp;

    loading: boolean = true;
    loadingError: boolean = false;
    offerLoading: boolean = true;
    offerLoadingError: boolean = false;
    content: any = null;
    navigationMenu: boolean[] = [true,true,true];
    offerDropdownOpen: boolean = false;
    offers = [];
    bestBookTab:string = "";
    likeButtonClicked: boolean = false;
    dislikeButtonClicked: boolean = false;
    faThumbsUp =faThumbsUp;
    faThumbsDown =faThumbsDown;
    
    constructor(
        private route: ActivatedRoute,
        public authHelper: AuthHelperService,
        private metaSetter: MetaSetterService,
        private gtmService: GoogleTagManagerService,
        private blogService: BlogService,
        private viewportScroller: ViewportScroller,
        public betService: BetService,
        @Inject(PLATFORM_ID) platformId: string,
        @Inject(DOCUMENT) public document: Document,
    ) {
        if (this.route.snapshot.paramMap.has("sportsbook")) {
            this.sportsbook = this.route.snapshot.paramMap.get("sportsbook").toLowerCase(); 
        }
        this.browserMode = isPlatformBrowser(platformId);
    }


    ngOnInit() {
        this.retrieveData();
        if (this.browserMode) {
            this.retrieveOfferData();
        }

    }

    ngAfterViewInit() {
        this.route.params.subscribe((data) => {
            if (data.sportsbook && this.sportsbook !== data.sportsbook ) {
                
                this.sportsbook = this.route.snapshot.paramMap.get("sportsbook").toLowerCase(); 
                this.retrieveData();

                if (this.browserMode) {
                    this.retrieveOfferData();
                }
            }
        });
    }

    retrieveData() {
        let slug = this.sportsbook + "-sportsbook-review";
        this.loading = true;
        this.loadingError = false;
        this.blogService.getCanadaSportsbooksReview(slug).subscribe({
            next: (data) => {
                this.content = data.metadata;
                this.metaSetter.setMeta({
                    title: this.content?.seo_title,
                    seoTitle: this.content?.seo_title,
                    description: this.content?.seo_description,
                    canonicalURL: `https://www.dimers.com/ca/betting/reviews/${this.sportsbook.toLowerCase()}`,
                    hrefLang: { 
                        "htmlLang": "en-CA",
                        "alternate": [
                            {'en-CA': `https://www.dimers.com/ca/betting/reviews/${this.sportsbook.toLowerCase()}`}
                        ]
                    }
                });
                this.loading = false;
            },
            error: () => {
                this.loading = false;
                this.loadingError = true;
            }
        });

    }

    retrieveOfferData() {
        this.offerLoading = true;
        this.offerLoadingError = false;
        this.stateCode = 'ontario';
        this.state = 'ontario';
        //set defualt state as Ontario '6579e31cb56fd8000882b48c'
        this.blogService.getCosmicOffersByStateId('6579e31cb56fd8000882b48c', "Best Offers").subscribe(
            {
                next: (items) => {
                    this.offers = items;
                    this.offers.sort((a) => {
                        return a.metadata.sportsbook[0].slug.includes(this.sportsbook) ? -1 : 1;
                    });
                    this.bestBookTab = this.offers[0].metadata.sportsbook[0].slug;

                    items = items.filter(offer => offer.metadata.sportsbook[0].slug.includes(this.sportsbook));
                    if (items.length) {
                        this.featureOffer = items[0];
                    }
                    else {
                        this.featureOffer = null;
                    }
                    this.offerLoading = false;
                }, error: () => {
                    this.offerLoading = false;
                    this.offerLoadingError = true;
                }
            });
    }
    
    toggleShow(bestBook: string) {
        this.bestBookTab = bestBook;
    }
    
    scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    redirectBookiePromoLink(url: string, queryParams: Record<string, string>, bookmaker: any) {
        const combinedUrl = url + (url.includes("?") ? "&" : "?") + new URLSearchParams(queryParams).toString();
        if (this.browserMode) {
            const gtmTag = {
                event: "exit",
                event_category: "reviews",
                event_label: bookmaker.slug,
            };

            this.gtmService.pushTag(gtmTag)
                .then(() => {
                    window.open(combinedUrl, "_self");
                })
                .catch(e => {
                    console.error("Google Tag Manager Script failed: " + e)
                    window.open(combinedUrl, "_self");
                });
        }
    }

    toggleOpen(index: number) {
        this.navigationMenu[index] = !this.navigationMenu[index];
    }

    jumpToSection(tabMenu: string): void {
        this.navTab = tabMenu;
        this.viewportScroller.scrollToAnchor(tabMenu);
    }

    toggleOfferDropdown(): void {
        this.offerDropdownOpen = !this.offerDropdownOpen;
    }
}


