import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-best-offer-card',
    templateUrl: './best-offer-card.component.html',
    styleUrls: ['./best-offer-card.component.scss']
})
export class BestOfferCardComponent {

    @Input() offer: any;
    @Input() pageLocation?: string;
    @Input() stateCode?:string;

    faChevronDown = faChevronDown;
    faChevronUp = faChevronUp;
    offerDropdownOpen: boolean = false;

    browserMode = false;

    constructor(
        private gtmService: GoogleTagManagerService,
        @Inject(DOCUMENT) public document: Document,
        @Inject(PLATFORM_ID) platformId: string,
    ) {
        this.browserMode = isPlatformBrowser(platformId);
     }

    // ngOnInit() {
        // if (this.browserMode) {
            // this.growthbookService.showDynamicOfferTitles$.subscribe((data)=> {
            //     this.showDynamicOfferTitles = data;
            // });
        // }
    // }

    redirectBookiePromoLink(url: string, queryParams: Record<string, string>, bookmaker: any) {
        // if (!this.excludeBookies(bookmaker?.slug || "")) {
        //     queryParams.c = (this.router.url.split("/")[1] || 'home') + (
        //         this.authHelper.user
        //             ? ('|' + this.authHelper.user.id)
        //             : '|visitor'
        //     );
        // }

        if (bookmaker.slug === 'underdog-fantasy') {
            delete queryParams['promo']
        }        
        const combinedUrl = url + (url.includes("?") ? "&" : "?") + new URLSearchParams(queryParams).toString();

        if (typeof window !== "undefined") {
            const gtmTag = {
                event: "exit",
                event_category: "welcome",
                event_label: bookmaker.slug,
                event_location: this.pageLocation
            };

            this.gtmService.pushTag(gtmTag)
                .then(() => {
                    window.open(combinedUrl, "_self");
                })
                .catch(e => {
                    console.error("Google Tag Manager Script failed: " + e)
                    window.open(combinedUrl, "_self");
                });
        }

        // remove smartlook functionality
        // const eventName = 'OfferClaimNowButtonClicked';
        // let property = {
        // "offer-placement-location": this.pageLocation,
        // "page-path": this.document?.location.pathname,
        // 'offer-bookmaker': bookmaker.slug,
        // "offer-state-code": this.stateCode
        // };
        // window.smartlook('track', eventName, property);
        // this.ClaimButtonClickedGB();
    }

    // excludeBookies(bookie: string) {
    //     bookie = bookie.replace(/\s/g, '').toLowerCase()
    //     switch (bookie) {
    //         case 'bet365':
    //         case 'bet_365':
    //             return true
    //         case 'pointsbet':
    //             return true
    //         case 'betmgm':
    //             return true
    //         default:
    //             return false
    //     }
    // }

    toggleOfferDropdown(event, slug:string): void {
        event.stopPropagation();
        this.offerDropdownOpen = !this.offerDropdownOpen;

        // remove smartlook functionality
        // const eventName = 'HowToClaimButtonClicked';
        // let property = {
        // "offer-placement-location": this.pageLocation,
        // "page-path": this.document?.location.pathname,
        // 'offer-bookmaker': slug,
        // "offer-state-code": this.stateCode
        // };
        // window.smartlook('track', eventName, property);
    }

    // remove smartlook functionality
    // sendTosmartLook(event, slug:string) {
    //     event.stopPropagation();

    //     const eventName = 'ReadReviewButtonClicked';
    //     let property = {
    //     "offer-placement-location": this.pageLocation,
    //     "page-path": this.document?.location.pathname,
    //     'offer-bookmaker': slug,
    //     "offer-state-code": this.stateCode
    //     };
    //     window.smartlook('track', eventName, property);
    // }

    // ClaimButtonClickedGB() {
    //     window.analytics.track("Claim Now Button Clicked", {
    //         "page-path": document?.location.pathname,
    //         "offer-bookmaker": this.offer?.metadata?.sportsbook?.[0]?.slug,
    //         "offer-state-code": this.stateCode,
    //         "offer-placement-location": this.pageLocation,
    //         "sport": this.sport?  this.sport.toUpperCase(): undefined,
    //     });
    // }

    // ReviewButtonClickedGB() {
    //     window.analytics.track("Review Button Clicked", {
    //         "page-path": document?.location.pathname,
    //         "offer-bookmaker": this.offer?.metadata?.sportsbook?.[0]?.slug,
    //         "offer-state-code": this.stateCode,
    //         "offer-placement-location": this.pageLocation,
    //         "sport": this.sport?  this.sport.toUpperCase(): undefined,
    //     })
    // }

}
