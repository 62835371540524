import { Component, Input } from '@angular/core';
import { faClipboardCheck} from '@fortawesome/pro-regular-svg-icons';


@Component({
  selector: 'app-reviewer-info',
  templateUrl: './reviewer-info.component.html',
  styleUrls: ['./reviewer-info.component.scss']
})
export class ReviewerInfoComponent {
    @Input() author?:Record<string, any>;
    @Input() reviewTime?:string;

    faClipboardCheck = faClipboardCheck;

}
