import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-why-you-can-trust',
  templateUrl: './why-you-can-trust.component.html',
  styleUrls: ['./why-you-can-trust.component.scss']
})
export class WhyYouCanTrustComponent {
    @Input() data?:Record<string, any>;
}
