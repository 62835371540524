import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie';
import { GoogleTagManagerService } from '../angular-google-tag-manager.service';
import { AuthHelperService } from '../auth-helper.service';

@Injectable({
	providedIn: 'root'
})
export class MetaSetterService {

	constructor(
		private meta: Meta,
		private titleService: Title,
		private gtmService: GoogleTagManagerService,
		@Inject(DOCUMENT) private dom: Document,
		private authHelper: AuthHelperService,
		private cookieService: CookieService,
	) { }

	setMeta(
		values: {
			title?: string,
			seoTitle?: string,
			description?: string,
			metaImageURL?: string,
			canonicalURL?: string,
			structuredData?: Record<string, any>;
			ampURL?: string;
			previousURL?: string;
			nextURL?: string;
			noIndex?: boolean;
            hrefLang?:Record<string, any>;
		},
		openGraphTitleMatchesPageTitle: boolean = false,
	) {
		if (values.title) {
			this.titleService.setTitle(values.title);
			this.meta.updateTag({
				property: "og:title",
				content: openGraphTitleMatchesPageTitle ? values.title : (values.seoTitle || values.title)
			});
			if (openGraphTitleMatchesPageTitle) {
				this.meta.updateTag({
					property: "twitter:title",
					content: values.seoTitle || values.title
				})
			} else {
				this.meta.removeTag("property='twitter:title'");
			}
		}

		if (values.description) {
			values.description = values.description.replace(
				/<[^>]*(>|$)|&nbsp;|&rsquo;|&ldquo;|&zwnj;|&raquo;|&laquo;|&gt;/g,
				' '
			);
			this.meta.updateTag({name: "description", content: values.description});
			this.meta.updateTag({name: "twitter:description", content: values.description});
			this.meta.updateTag({property: "og:description", content: values.description});
		}

		if (values.metaImageURL) {
			this.meta.updateTag({property: "og:image", content: values.metaImageURL});
			this.meta.updateTag({property: "twitter:image", content: values.metaImageURL});
		}


		// set canonical URL - provided one if there, otherwise current URL converted to lowercase
		const canURL = (values.canonicalURL || this.dom.URL).toLowerCase();
		const existingCanonical = this.dom.head.querySelector(`link[rel="canonical"]`)
		if (existingCanonical) {
			existingCanonical.setAttribute("href", canURL);
		} else {
			const link: HTMLLinkElement = this.dom.createElement("link");
			link.setAttribute("rel", "canonical");
			link.setAttribute("href", canURL);
			this.dom.head.appendChild(link);
		}

        // set hreflang if provided, otherwise wipe it
        const existingHrefLang = this.dom.head.querySelectorAll(`link[rel="alternate"]`);
        if(existingHrefLang.length !==0 ){
            existingHrefLang.forEach(element => {
                element.remove();
                this.dom.documentElement.setAttribute("lang", "en");
            });
            if(values.hrefLang) {
                values.hrefLang.alternate.forEach((item)=> {
                    const link: HTMLLinkElement = this.dom.createElement("link");
                    link.setAttribute("rel", "alternate");
                    link.setAttribute("hreflang", Object.keys(item)[0]);
                    link.setAttribute("href", Object.values(item)[0].toString());
                    this.dom.head.appendChild(link);
                })  
                this.dom.documentElement.setAttribute("lang", values.hrefLang.htmlLang);
            }
        }else if (values.hrefLang) {
            values.hrefLang.alternate.forEach((item)=> {
                const link: HTMLLinkElement = this.dom.createElement("link");
                link.setAttribute("rel", "alternate");
                link.setAttribute("hreflang", Object.keys(item)[0]);
                link.setAttribute("href", Object.values(item)[0].toString());
                this.dom.head.appendChild(link);
            })  
                this.dom.documentElement.setAttribute("lang", values.hrefLang.htmlLang);
        }

		// set AMP URL if provided, otherwise wipe it
		const existingAMP: HTMLLinkElement = this.dom.head.querySelector(`link[rel="amphtml"]`);
		if (existingAMP) {
			if (values.ampURL) {
				existingAMP.href = values.ampURL;
			} else {
				existingAMP.remove();
			}
		} else if (values.ampURL) {
			const link: HTMLLinkElement = this.dom.createElement("link");
			link.setAttribute("rel", "amphtml");
			link.setAttribute("href", values.ampURL);
			this.dom.head.appendChild(link);
		}

		// set previous URL if provided, otherwise wipe it
		const existingPrev: HTMLLinkElement = this.dom.head.querySelector(`link[rel="prev"]`);
		if (existingPrev) {
			if (values.previousURL) {
				existingPrev.href = values.previousURL;
			} else {
				existingPrev.remove();
			}
		} else if (values.previousURL) {
			const link: HTMLLinkElement = this.dom.createElement("link");
			link.setAttribute("rel", "prev");
			link.setAttribute("href", values.previousURL);
			this.dom.head.appendChild(link);
		}

		// set next URL if provided, otherwise wipe it
		const existingNext: HTMLLinkElement = this.dom.head.querySelector(`link[rel="next"]`);
		if (existingNext) {
			if (values.nextURL) {
				existingNext.href = values.nextURL;
			} else {
				existingNext.remove();
			}
		} else if (values.nextURL) {
			const link: HTMLLinkElement = this.dom.createElement("link");
			link.setAttribute("rel", "next");
			link.setAttribute("href", values.nextURL);
			this.dom.head.appendChild(link);
		}

		// set structured data if provided, otherwise wipe it
		const existingStructured: HTMLScriptElement = this.dom.head.querySelector(`#page-scripted-data`);
		if (existingStructured) {
			existingStructured.textContent = values.structuredData ? JSON.stringify(values.structuredData) : "";
		} else if (values.structuredData) {
			const script: HTMLScriptElement = this.dom.createElement("script");
			script.setAttribute("type", "application/ld+json");
			script.setAttribute("id", "page-scripted-data");
			script.textContent = JSON.stringify(values.structuredData);
			this.dom.head.appendChild(script);
		}

		// set noindex meta tag if requested, otherwise wipe it if it exists
		const existingNoindex = this.dom.head.querySelector(`meta[name="robots"][content="noindex"]`);
		if (values.noIndex) {
			if (!existingNoindex) {
				const newNoindex: HTMLMetaElement = this.dom.createElement("meta");
				newNoindex.setAttribute("name", "robots");
				newNoindex.setAttribute("content", "noindex");
				this.dom.head.appendChild(newNoindex);
			}
		} else {
			if (existingNoindex) {
				this.dom.head.removeChild(existingNoindex)
			}
		}


		if (typeof window !== "undefined") {
			const gtmTag = {
				event: "page",
				pageName: window.location.pathname.toLowerCase(),
				// if the user is logged in, mark them as a registered user
				// if they're not logged in, but they have the cookie that indicates that they're subscribed to the newsletter, mark them as a subscriber
				// if they're neither logged in nor a known subscriber, mark them as a visitor
				surge_tags: `dimers,${this.authHelper.user ? 'registered' : (this.cookieService.hasKey("mtc_id") ? 'subscribed' : 'visitor')}`,
				email: this.authHelper.user?.email,
				firstname: this.authHelper.user?.user_metadata?.first_name,
				lastname: this.authHelper.user?.user_metadata?.last_name,
				auth0userid: this.authHelper.user?.sub,
				auth0sports: this.authHelper.user?.user_metadata?.user_sports ? "," + this.authHelper.user?.user_metadata?.user_sports?.join(",") + "," : undefined,
				auth0bookmakers: this.authHelper.user?.user_metadata?.user_books ? "," + this.authHelper.user?.user_metadata?.user_books?.join(",") + "," : undefined,
			};

			// remove any undefined fields from the tag object
			Object.keys(gtmTag).forEach(k => {
				if (typeof gtmTag[k] === "undefined") {
					delete gtmTag[k];
				}
			})

			this.gtmService.pushTag(gtmTag)
				.catch(e => {
					throw new Error("Google Tag Manager Script failed: " + e)
				});

			// (window as any)?._taboola?.push({article:'auto', url: canURL});
		}
	}

	setStructuredData(structuredData: Array<Record<string, any>>) {
			// set structured data if provided, otherwise wipe it
			const existingStructured: HTMLScriptElement = this.dom.head.querySelector(`#page-scripted-data`);
			if (existingStructured) {
				existingStructured.textContent = structuredData ? JSON.stringify(structuredData) : "";
			} else if (structuredData) {
				const script: HTMLScriptElement = this.dom.createElement("script");
				script.setAttribute("type", "application/ld+json");
				script.setAttribute("id", "page-scripted-data");
				script.textContent = JSON.stringify(structuredData);
				this.dom.head.appendChild(script);
			}
	}
}
