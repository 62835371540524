import { DOCUMENT } from '@angular/common';
import { Component, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { faStar as faStarRegular, faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faStarSolid, faChevronsUp, faChevronsDown, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Observable, map, shareReplay } from 'rxjs';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { BetService } from 'src/app/bet.service';
import { BlogService } from 'src/app/blog.service';
import { GeneralService } from 'src/app/general.service';
import { LocalisationService } from 'src/app/localisation.service';
import { MatchBet } from 'src/app/match-bet.model';
import { MatchService } from 'src/app/match.service';
import { ShortlistService } from 'src/app/shortlist.service';

@Component({
    selector: 'app-value-bet-row',
    templateUrl: './value-bet-row.component.html',
    styleUrls: ['./value-bet-row.component.scss']
})
export class ValueBetRowComponent {

    @Input() play: MatchBet;
    @Input() singleMatch: boolean;
    @Input() proOnly: boolean;

    faStarSolid = faStarSolid;
    faStarRegular = faStarRegular;

    faChevronsUp = faChevronsUp;
    faChevronsDown = faChevronsDown;
    faTimes = faTimes;
    faCircleInfo= faCircleInfo;

    stateCode: string = "";
    state: string = "";
    confirmPopupVisible: boolean = false;
    timeoutId: any;

    proUser$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
        map((detailedUser) => {
            return !!detailedUser?.app_metadata?.dimers_pro
        }),
        shareReplay(1)
    );

    constructor(
        public matchService: MatchService,
        public generalService: GeneralService,
        public shortlistService: ShortlistService,
        public betService: BetService,
        public localisationService: LocalisationService,
        @Inject(DOCUMENT) public document: Document,
        private authHelper: AuthHelperService,
        private blogService: BlogService,
        private gtmService: GoogleTagManagerService,
        private router: Router,
    ) {
    }

    oddsButtonClick() {
        this.betService.sharedLegalOnlineBettingData$.subscribe(data => {
            if (data) {
                this.betService.goToPromoLink(this.betService.bookmakerLink(this.play.bookmaker, this.play.linkDetails), this.play.bookmaker, 'quick-picks');
            }
            else {
                this.betService.setSharedModalData(true);
                this.betService.setSharedBookMakerData(this.betService.stylisedBookName(this.play.bookmaker));
                this.betService.setSharedOddsRedirectLinkData(this.betService.bookmakerLink(this.play.bookmaker, this.play.linkDetails));
            }
        });
    }

    openProModal() {
        // Open Pro modal
        const gtmTag = {
            event: "pro_modal_appearance",
            event_category: "masked-bet",
            // event_label: this.bookieCode,
        };

        this.gtmService.pushTag(gtmTag)
            .catch(e => {
                console.error("Google Tag Manager Script failed: " + e)
            });
        this.blogService.setProModalData(true);
    }

    jumpToSection(section:string): void {
        this.router.navigate(['/best-bets'], { fragment: section });
    }
}
