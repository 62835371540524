import { Component, Input } from '@angular/core';
import { LocalisationService } from 'src/app/localisation.service';
import { ShortlistService } from 'src/app/shortlist.service';
import { faStar as faStarReg } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faStarSolid, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { MatchService } from 'src/app/match.service';

@Component({
    selector: 'app-shortlist-button',
    templateUrl: './shortlist-button.component.html',
    styleUrls: ['./shortlist-button.component.scss']
})
export class ShortlistButtonComponent {

    @Input() matchID: string;
    @Input() sport: string;
    @Input() mobile: boolean;

    confirmPopupVisible: boolean = false;
    timeoutId: any;

    faStarSolid = faStarSolid;
    faStarReg = faStarReg;
    faTimes = faTimes;

    constructor(
        public shortlistService: ShortlistService,
        public localisationService: LocalisationService,
        public matchService: MatchService,

    ) { }

    toggleMatchInShortlist() {
        this.shortlistService.toggleMatchInShortlist(this.matchID)
            .then(success => {
                if (success) {
                    console.log('Operation completed successfully.');
                    if (this.mobile) {
                        // Clear the existing timeout if it exists
                        if (this.timeoutId) {
                            clearTimeout(this.timeoutId);
                        }
                        this.confirmPopupVisible = true;
                        // Close the modal after 5 seconds (5000 milliseconds)
                        this.timeoutId = setTimeout(() => {
                            this.confirmPopupVisible = false;
                        }, 5000);
                    }

                } else {
                    console.log('user is not login');
                }
            })
            .catch(error => {
                console.log('An error occurred:', error);
            });
    }

    closePopup() {
        this.confirmPopupVisible = false;
    }
}
