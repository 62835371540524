import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { mergeMap } from 'rxjs';
import { BetService } from 'src/app/bet.service';
import { BlogService } from 'src/app/blog.service';
import { STATES } from 'src/app/states';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-non-legal-modal',
    templateUrl: './non-legal-modal.component.html',
    styleUrls: ['./non-legal-modal.component.scss']
})
export class NonLegalModalComponent implements OnInit{
    
    bookmaker: string;
    oddsRedirectLink: string;
    // @Output() visibleChanged = new EventEmitter<any>();

    offer: any;
    offerLoading: boolean;
    offerError: boolean;
    browserMode:boolean;
    stateCode: string = "";
    state:string = "";

    formSending = false;
    formSent = false;
    formError = false;
    formValid = false;
    email: string = '';
    visible: boolean = false;
    faTimes = faTimes;

    constructor(
        private blogService: BlogService,
        public betService: BetService,
		@Inject(PLATFORM_ID) platformId: object,

    ) { 
		this.browserMode = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {

        this.betService.sharedModalData$.subscribe(data => this.visible = data);
        this.betService.sharedBookMakerData$.subscribe(data => this.bookmaker = data);
        this.betService.sharedOddsRedirectLinkData$.subscribe(data => this.oddsRedirectLink = data);

        if (this.browserMode) {
            this.blogService.userLocationData$
            .subscribe(loc => {
                if (loc?.state_name) {
                    this.state = loc.state_name;
                    this.stateCode = this.state.toLowerCase().replace(/ /g, '-');
                    //check if this user location is in the US state and their location is legal or not
                    if (STATES.some(s => (s.name.toLowerCase() === this.state.toLowerCase() && s.legalOnlineBetting === false))) {
                        this.betService.setSharedLegalOnlineBettingData(false);
                        this.loadDFSBookOffer();
                    } 
                    else {
                        this.betService.setSharedLegalOnlineBettingData(true);
                    }
                } 
            });
        }

	}

    setVisible() {
        // this.visible = false;
        this.betService.setSharedModalData(false);
        this.formSent = false;
    }

    sendEmail(): void {
        if (this.formValid) {
            this.formSending = true;
            this.formSent = false;
            this.formError = false;

            this.blogService.addNewsletterEmail(this.email).subscribe(
                (_) => {
                    this.formSent = true;
                    this.formSending = false;
                },
                () => {
                    this.formError = true;
                    this.formSending = false;
                }
            );
        }
    }

    verifyForm(): void {
        if (!this.email) {
            this.formValid = false;
        } else if (!/.+@.+\..+/.test(this.email)) {
            this.formValid = false;
        } else {
            this.formValid = true;
        }
    }

    loadDFSBookOffer(): void {
        this.offerLoading = true;
        this.offerError = false;

        this.blogService.getStateId(this.stateCode).pipe(
            mergeMap(stateId => this.blogService.getCosmicOffersByStateId(stateId, "Sidebar Welcome Offers"))
        ).subscribe((offers) => {
            this.offer = offers.sort((a, b) => {
                let x = a.metadata.index?.sidebar_offer_index ? a.metadata.index?.sidebar_offer_index : undefined;
                let y = b.metadata.index?.sidebar_offer_index ? b.metadata.index?.sidebar_offer_index : undefined;
                return x- y;
            });
            this.offer = offers[0];
            this.offerLoading = false;
        }, () => {
            this.offerError = true;
        });

      }
      

}

