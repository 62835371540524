<div class="content">
  <div class="heading">
    More from Dimers
  </div>
<section class="accordion">
  <section class="accordion-tabs" #subLinkTabs>
       <button class="accordion-tab schedule" data-actab-group="0" data-actab-id="0" (click)="this.toggleShow('schedule')"
        [class.accordion-active]="activeSubFooterCategory === 'schedule'">
        predictions
       </button>
       <button class="accordion-tab best-bets" data-actab-group="0" data-actab-id="1" (click)="this.toggleShow('best-bets')"
        [class.accordion-active]="activeSubFooterCategory === 'best-bets'">
        best bets
       </button>
       <button class="accordion-tab best-props" data-actab-group="0" data-actab-id="2" (click)="this.toggleShow('best-props')"
        [class.accordion-active]="activeSubFooterCategory === 'best-props'">
        best props
       </button>
       <!-- <button class="accordion-tab super-bowl" data-actab-group="0" data-actab-id="3" (click)="this.toggleShow('super-bowl')"
       [class.accordion-active]="activeSubFooterCategory === 'super-bowl'">
       super bowl
      </button> -->
       <button class="accordion-tab best-trends" data-actab-group="0" data-actab-id="3" (click)="this.toggleShow('best-trends')"
        [class.accordion-active]="activeSubFooterCategory === 'best-trends'">
        best trends
       </button>
       <button class="accordion-tab best-books" data-actab-group="0" data-actab-id="4" (click)="this.toggleShow('best-books')"
        [class.accordion-active]="activeSubFooterCategory === 'best-books'">
        Best Sportsbooks
       </button>
       <button class="accordion-tab sportsbook-review" data-actab-group="0" data-actab-id="5" (click)="this.toggleShow('sportsbook-review')"
       [class.accordion-active]="activeSubFooterCategory === 'sportsbook-review'">
       Reviews
        </button>
       <button class="accordion-tab futures" data-actab-group="0" data-actab-id="6" (click)="this.toggleShow('futures')"
        [class.accordion-active]="activeSubFooterCategory === 'futures'">
        futures
       </button>
       <button class="accordion-tab odds" data-actab-group="0" data-actab-id="7" (click)="this.toggleShow('odds')"
        [class.accordion-active]="activeSubFooterCategory === 'odds'">
        odds
       </button>
       <button class="accordion-tab news articles" data-actab-group="0" data-actab-id="8" (click)="this.toggleShow('news')"
        [class.accordion-active]="activeSubFooterCategory === 'news' || activeSubFooterCategory === 'articles'">
        news
       </button>
       <button class="accordion-tab subscription" data-actab-group="0" data-actab-id="9" (click)="this.toggleShow('subscription')"
       [class.accordion-active]="activeSubFooterCategory === 'subscription'">
       Dimers Pro
      </button>
  </section>
  <section class="according-content" #subLinkItems>
    <article class="accordion-item schedule" data-actab-group="0" data-actab-id="0"
      [class.accordion-active]="activeSubFooterCategory === 'schedule'">
      <span class="accordion-item__label" (click)="this.toggleShow('schedule')" >Predictions</span>
      <div class="accordion-item__container">
        <ul>
            <ng-container *ngFor = "let link of predictionLinks">
                <li>
                  <app-sport-icon [code]="link.code" colour="black"></app-sport-icon>
                  <a [routerLink]="link.path"> {{ link.shortName }} Predictions</a>
                </li>
            </ng-container>
        </ul>
      </div>
    </article>
    <article class="accordion-item best-bets" data-actab-group="0" data-actab-id="1"
      [class.accordion-active]="activeSubFooterCategory === 'best-bets'">
      <span class="accordion-item__label" (click)="this.toggleShow('best-bets')">Best Bets</span>
      <div class="accordion-item__container">
        <ul>
          <ng-container *ngFor = "let link of bestBetsLinks">
            <li>
              <app-sport-icon [code]="link.code" colour="black"></app-sport-icon>
              <a [routerLink]="link.path"> {{ link.shortName }} Best Bets</a>
            </li>
        </ng-container>
        </ul>
      </div>
    </article>
    <article class="accordion-item best-props" data-actab-group="0" data-actab-id="2"
      [class.accordion-active]="activeSubFooterCategory === 'best-props'">
      <span class="accordion-item__label" (click)="this.toggleShow('best-props')">Best Props</span>
      <div class="accordion-item__container">
        <ul>
          <ng-container *ngFor = "let link of bestPropsLinks">
            <li>
              <app-sport-icon [code]="link.code" colour="black"></app-sport-icon>
              <a [routerLink]="link.path"> {{ link.shortName }} Best Props</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </article>
    <!-- <article  class="accordion-item super-bowl" data-actab-group="0" data-actab-id="3"
    [class.accordion-active]="activeSubFooterCategory === 'super-bowl'">
    <span class="accordion-item__label" (click)="this.toggleShow('super-bowl')">Super Bowl</span>
    <div class="accordion-item__container">
      <ul>
        <li>
          <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
            height="18" width="18">
          <a routerLink="/super-bowl"> Super Bowl LVII</a>
        </li>
        <li>
          <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
            height="18" width="18">
          <a routerLink="/super-bowl/prop-bets-sheet"> Super Bowl Props Sheet </a>
        </li>
        <li>
          <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
            height="18" width="18">
          <a routerLink="/super-bowl/betting-guide"> Super Bowl Betting Guide</a>
        </li>
        <li>
          <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
            height="18" width="18">
          <a routerLink="/super-bowl/squares"> Super Bowl Squares</a>
        </li>
        <li>
          <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
            height="18" width="18">
          <a routerLink="/super-bowl/party-pack"> Super Bowl Party pack</a>
        </li>
        <li>
          <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
            height="18" width="18">
          <a routerLink="/news/super-bowl-lvii-first-anytime-touchdown-scorers-predictions-31128"> Super Bowl Touchdown Scorers</a>
        </li>
        <li>
          <img class="super-bowl-icon" alt="icon" loading="lazy" src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
            height="18" width="18">
          <a routerLink="news/super-bowl-best-prop-bets-for-chiefs-vs-eagles-31121"> Super Bowl Best Props</a>
        </li>
      </ul>
    </div>
    </article> -->
    <article class="accordion-item best-trends" data-actab-group="0" data-actab-id="3"
      [class.accordion-active]="activeSubFooterCategory === 'best-trends'">
      <span class="accordion-item__label" (click)="this.toggleShow('best-trends')">Best Trends</span>
      <div class="accordion-item__container">
        <ul>
          <li><app-sport-icon code="NFL" colour="black"></app-sport-icon><a routerLink="/best-trends/nfl"> NFL Best Trends</a></li>
          <li><app-sport-icon code="CFB" colour="black"></app-sport-icon><a routerLink="/best-trends/cfb"> College Football Best Trends</a></li>
          <li><app-sport-icon code="NBA" colour="black"></app-sport-icon><a routerLink="/best-trends/nba"> NBA Best Trends</a></li>
          <li><app-sport-icon code="WNBA" colour="black"></app-sport-icon><a routerLink="/best-trends/wnba"> WNBA Best Trends</a></li>
          <li><app-sport-icon code="MLB" colour="black"></app-sport-icon><a routerLink="/best-trends/mlb"> MLB Best Trends</a></li>
          <li><app-sport-icon code="CBB" colour="black"></app-sport-icon><a routerLink="/best-trends/cbb"> College Basketball Best Trends</a></li>
        </ul>
      </div>
    </article>
    <article class="accordion-item best-books" data-actab-group="0" data-actab-id="4"
      [class.accordion-active]="activeSubFooterCategory === 'best-books'">
      <span class="accordion-item__label" (click)="this.toggleShow('best-books')">Best Sportsbooks</span>
      <div class="accordion-item__container">
        <ul>
            <li>
                <img src="https://imgix.cosmicjs.com/b88c9e80-5916-11ee-94c0-5f69598aa628-Arizona.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/arizona">Best Sportsbooks Arizona</a>
            </li>
            <li><img src="https://imgix.cosmicjs.com/bdfb9c40-5916-11ee-94c0-5f69598aa628-Colorado.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/colorado">Best Sportsbooks Colorado</a>
            </li>
            <li><img src="https://imgix.cosmicjs.com/c4e245e0-5916-11ee-94c0-5f69598aa628-Illinois.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/illinois">Best Sportsbooks Illinois</a>
            </li>
        
            <li><img src="https://imgix.cosmicjs.com/cb699170-5916-11ee-94c0-5f69598aa628-Indiana.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/indiana">Best Sportsbooks Indiana</a>
            </li>
            <li>
                <img src="https://imgix.cosmicjs.com/df9daff0-5916-11ee-94c0-5f69598aa628-Kansas.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/kansas">Best Sportsbooks Kansas</a>
            </li>
            <li>
                <img src="https://imgix.cosmicjs.com/b3f27cf0-5916-11ee-94c0-5f69598aa628-Kentucky.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/kentucky">Best Sportsbooks Kentucky</a>
            </li>
            <li><img src="https://imgix.cosmicjs.com/e6d4ea90-5916-11ee-94c0-5f69598aa628-Louisiana.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/louisiana">Best Sportsbooks Louisiana</a>
            </li>
        
            <li><img src="https://imgix.cosmicjs.com/ec9a93d0-5916-11ee-94c0-5f69598aa628-Maryland.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/maryland">Best Sportsbooks Maryland</a>
            </li>
        
            <li><img src="https://imgix.cosmicjs.com/f1d4c5f0-5916-11ee-94c0-5f69598aa628-Massachusetts.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/massachusetts">Best Sportsbooks Massachusetts</a>
            </li>
        
            <li><img src="https://imgix.cosmicjs.com/f6de7230-5916-11ee-94c0-5f69598aa628-Michigan.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/michigan">Best Sportsbooks Michigan</a>
            </li>
            
            <li><img src="https://imgix.cosmicjs.com/7aa6c130-b038-11ee-baae-2b7553ec3713-New-Jersey-Highlight.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/new-jersey">Best Sportsbooks New Jersey</a>
            </li>
        
            <li><img src="https://imgix.cosmicjs.com/0b0438d0-5917-11ee-94c0-5f69598aa628-New-York.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/new-york">Best Sportsbooks New York</a>
            </li>
            <li>
                <img src="https://imgix.cosmicjs.com/79d18eb0-d9ec-11ee-a698-6f33e3e4a628-5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/north-carolina">Best Sportsbooks North Carolina</a>
            </li>
            <li><img src="https://imgix.cosmicjs.com/0fd066e0-5917-11ee-94c0-5f69598aa628-Ohio.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/ohio">Best Sportsbooks Ohio</a>
            </li>
        
            <li><img src="https://imgix.cosmicjs.com/14d99df0-5917-11ee-94c0-5f69598aa628-Pennsylvania.svg?auto=format,compress&w=25"
                    width="20" height="20" alt="icon" loading="lazy">
                <a routerLink="/best-books/pennsylvania">Best Sportsbooks Pennsylvania</a>
            </li>
            <li><img src="https://imgix.cosmicjs.com/f54e69d0-1346-11ef-b71b-ef79521db5fa-Ontario.svg"
                width="20" height="20" alt="icon" loading="lazy">
            <a routerLink="/ca/betting/ontario">Best Sportsbooks Ontario</a>
            </li>
        
            <!-- <li><app-sportsbooks-icon sportBookCode="iowa"></app-sportsbooks-icon><a routerLink="/best-books/iowa">Best Sportsbooks Iowa</a></li> -->
        </ul>
      </div>
    </article>
    <article class="accordion-item sportsbook-review" data-actab-group="0" data-actab-id="5"
    [class.accordion-active]="activeSubFooterCategory === 'sportsbook-review'">
    <span class="accordion-item__label" (click)="this.toggleShow('sportsbook-review')">Reviews</span>
    <div class="accordion-item__container">
      <ul>
        <ng-container *ngFor="let sportsbook of sportsbooks">
            <li>
                <a class="more-link" href="/best-books/reviews/{{sportsbook.metadata.sportsbook.slug}}"
                    routerLink="/best-books/reviews/{{sportsbook.metadata.sportsbook.slug}}">
                    <div class="flex gap-2 items-center">
                        <img width="20" height="20" alt="icon" class="book-icon w-5 h-5" loading="lazy"
                            src="{{sportsbook.metadata.sportsbook.metadata.promos_small_logo.imgix_url}}?auto=format,compress&w=20">
                        {{sportsbook.metadata.sportsbook.title}}
                    </div>
                </a>
            </li>
        </ng-container>
        <ng-container *ngFor="let item of dfsProviders">
            <li>
                <a class="more-link" href="/dfs/reviews/{{item.metadata.sportsbook.slug}}"
                    routerLink="/dfs/reviews/{{item.metadata.sportsbook.slug}}">
                    <div class="flex gap-2 items-center">
                        <img width="20" height="20" alt="icon" class="book-icon w-5 h-5" loading="lazy"
                            src="{{item.metadata.sportsbook.metadata.promos_small_logo.imgix_url}}?auto=format,compress&w=20">
                        {{item.metadata.sportsbook.title}}
                    </div>
                </a>
            </li>
        </ng-container>
        
        <!-- <li>
            <a class="more-link" href="/best-books/reviews/fanduel" routerLink="best-books/reviews/fanduel">
                <div class="flex gap-2">
                    <img height="20" width="20" alt="" class="book-icon" loading="lazy"
                        src="https://imgix.cosmicjs.com/98ac2470-4861-11ee-89ab-17371fc03105-FanDuelSportsbook-icon.svg?auto=format,compress&w=20">
                    FanDuel
                </div>
            </a>
           
        </li>
        <li>
            <a class="more-link" href="/best-books/reviews/draftkings" routerLink="best-books/reviews/draftkings">
                <div class="flex gap-2">
                    <img height="20" width="20" alt="" class="book-icon" loading="lazy"
                        src="https://imgix.cosmicjs.com/98e5f920-4861-11ee-89ab-17371fc03105-DraftKings-icon.svg?auto=format,compress&w=20">
                    DraftKings
                </div>
            </a>
          
        </li>
        <li>
            <a class="more-link" href="/best-books/reviews/bet365" routerLink="best-books/reviews/bet365">
                <div class="flex gap-2">
                    <img height="20" width="20" alt="" class="book-icon" loading="lazy"
                        src="https://imgix.cosmicjs.com/98e6e380-4861-11ee-b0ba-f39b6f0c3501-Bet365-icon.svg?auto=format,compress&w=20">
                    bet365
                </div>
            </a>
           
        </li>
        <li>
            <a class="more-link" href="/best-books/reviews/betmgm" routerLink="best-books/reviews/betmgm">
                <div class="flex gap-2">
                    <img height="20" width="20" alt="" class="book-icon" loading="lazy"
                        src="https://imgix.cosmicjs.com/9911c410-4861-11ee-89ab-17371fc03105-BetMGM-icon.svg?auto=format,compress&w=20">
                    BetMGM
                </div>
            </a>
        </li>
        <li>
            <a class="more-link" href="/dfs/reviews/mojo" routerLink="/dfs/reviews/mojo">
                <div class="flex gap-2">
                    <img height="20" width="20" alt="" class="book-icon" loading="lazy"
                        src="https://imgix.cosmicjs.com/99123940-4861-11ee-b0ba-f39b6f0c3501-Mojo-icon.svg?auto=format,compress&w=20">
                    MOJO (DFS)
                </div>
            </a>
        </li> -->
      </ul>
    </div>
  </article>

    <article class="accordion-item futures" data-actab-group="0" data-actab-id="6"
      [class.accordion-active]="activeSubFooterCategory === 'futures'">
      <span class="accordion-item__label" (click)="this.toggleShow('futures')">Futures</span>
      <div class="accordion-item__container">
        <ul>
          <ng-container *ngFor = "let link of futuresLinks">
            <li>
              <app-sport-icon [code]="link.code" colour="black"></app-sport-icon>
              <a [routerLink]="link.path"> {{ link.shortName }} Futures</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </article>
    <article class="accordion-item odds" data-actab-group="0" data-actab-id="7"
      [class.accordion-active]="activeSubFooterCategory === 'odds'">
      <span class="accordion-item__label" (click)="this.toggleShow('odds')">Odds</span>
      <div class="accordion-item__container">
        <ul>
          <ng-container *ngFor = "let link of oddsLinks">
            <li>
              <app-sport-icon [code]="link.code" colour="black"></app-sport-icon>
              <a [routerLink]="link.path" [queryParams]="link.queryParams"> {{ link.shortName }} Odds</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </article>
    <article class="accordion-item news articles" data-actab-group="0" data-actab-id="8"
      [class.accordion-active]="activeSubFooterCategory === 'news' || activeSubFooterCategory === 'articles'">
      <span class="accordion-item__label" (click)="this.toggleShow('news')">News</span>
      <div class="accordion-item__container">
        <ul>
          <ng-container *ngFor = "let link of newsLinks">
            <li>
              <app-sport-icon [code]="link.code" colour="black"></app-sport-icon>
              <a [routerLink]="link.path" [queryParams]="link.queryParams"> {{ link.shortName }} News</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </article>
    <article class="accordion-item subscription" data-actab-group="0" data-actab-id="9"
    [class.accordion-active]="activeSubFooterCategory === 'subscription'">
    <span class="accordion-item__label" (click)="this.toggleShow('subscription')">Dimers Pro</span>
    <div class="accordion-item__container">
      <ul class="subscription">
        <li>
            <img alt="pro-logo" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-5 w-5 mr-2" loading="lazy">
            <a routerLink="/subscription">Pricing</a>
        </li>
        <li>
            <img alt="pro-logo" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-5 w-5 mr-2" loading="lazy">
            <a routerLink="/subscription/faq">FAQ</a>
        </li>
        <li>
            <img alt="pro-logo" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-5 w-5 mr-2" loading="lazy">
            <a routerLink="/subscription/how-to-use-dimers-pro">How to Use</a>
        </li>
        <li>
            <img alt="pro-logo" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-5 w-5 mr-2" loading="lazy">
            <a routerLink="/subscription/dimers-pro-vs-action-pro">Dimers Pro vs. Action PRO: Comprehensive Comparison</a>
        </li>
        <li>
            <img alt="pro-logo" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-5 w-5 mr-2" loading="lazy">
            <a routerLink="/subscription/dimers-pro-vs-betql">Dimers Pro vs. BetQL: Comprehensive Comparison</a>
        </li>
      </ul>
    </div>
  </article>

  </section>
</section>
</div>
