import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
@Component({
    selector: 'app-book-offer-banner',
    templateUrl: './book-offer-banner.component.html',
    styleUrls: ['./book-offer-banner.component.scss']
})
export class BookOfferBannerComponent {
    @Input() promo: any;
    @Input() pageLocation?: string;
    @Input() stateCode?: string;

    browserMode = false;
    faChevronDown = faChevronDown;
    faChevronUp = faChevronUp;
    offerDropdownOpen: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) platformId: string,
        private gtmService: GoogleTagManagerService,
        @Inject(DOCUMENT) public document: Document,
    ) {
        this.browserMode = isPlatformBrowser(platformId);
    }

    redirectBookiePromoLink(url: string, queryParams: Record<string, string>, bookmaker: any) {
        // if (!this.excludeBookies(bookmaker?.slug || "")) {
        //     queryParams.c = (this.router.url.split("/")[1] || 'home') + (
        //         this.authHelper.user
        //             ? ('|' + this.authHelper.user.id)
        //             : '|visitor'
        //     );
        // }
        if (bookmaker.slug === 'underdog-fantasy') {
            delete queryParams['promo']
        }
        const combinedUrl = url + (url.includes("?") ? "&" : "?") + new URLSearchParams(queryParams).toString();
        if (this.browserMode) {
            const gtmTag = {
                event: "exit",
                event_category: "reviews",
                event_label: bookmaker.slug,
                event_location: this.pageLocation
            };

            this.gtmService.pushTag(gtmTag)
                .then(() => {
                    window.open(combinedUrl, "_self");
                })
                .catch(e => {
                    console.error("Google Tag Manager Script failed: " + e)
                    window.open(combinedUrl, "_self");
                });
        }

        // remove smartlook functionality
        // const eventName = 'OfferClaimNowButtonClicked';
        // let property = {
        // "offer-placement-location": this.pageLocation,
        // "page-path": this.document?.location.pathname,
        // 'offer-bookmaker': bookmaker.slug,
        // "offer-state-code": this.stateCode
        // };
        // window.smartlook('track', eventName, property);
    }

    convertCase(str: string): string {
        const lower = String(str).toLowerCase()
        return lower.replace(/(^| )(\w)/g, function (x) {
            return x.toUpperCase()
        })
    }

    // excludeBookies(bookie: string): boolean {
    //     bookie = bookie.replace(/\s/g, '').toLowerCase()
    //     switch (bookie) {
    //         case 'bet365':
    //         case 'bet_365':
    //             return true
    //         case 'pointsbet':
    //             return true
    //         case 'betmgm':
    //             return true
    //         default:
    //             return false
    //     }
    // }

    toggleOfferDropdown(event, slug): void {
        event.stopPropagation();
        this.offerDropdownOpen = !this.offerDropdownOpen;

        // remove smartlook functionality
        // const eventName = 'HowToClaimButtonClicked';
        // let property = {
        // "offer-placement-location": this.pageLocation,
        // "page-path": this.document?.location.pathname,
        // 'offer-bookmaker': slug,
        // "offer-state-code": this.stateCode
        // };
        // window.smartlook('track', eventName, property);
    }

    // remove smartlook functionality
    // sendTosmartLook(event, slug:string) {
    //     event.stopPropagation();

    //     const eventName = 'ReadReviewButtonClicked';
    //     let property = {
    //     "offer-placement-location": this.pageLocation,
    //     "page-path": this.document?.location.pathname,
    //     'offer-bookmaker': slug,
    //     "offer-state-code": this.stateCode
    //     };
    //     window.smartlook('track', eventName, property);
    // }

}
