<div class="fixed w-full h-full top-0 left-0 z-[999] no-doc-scroll"  *ngIf="mobileFilterOpen">
    <div class="backdrop-blur-sm bg-black/50 w-full h-full" (click)="closeMobileFilter()"></div>
    <div class="fliters-mobile w-full max-h-[90vh] sm:max-h-[80vh] md:w-[500px] divide-y no-doc-scroll relative flex flex-col">
        <div class="title text-xl font-semibold leading-7 flex justify-between items-center text-gray-900 p-6">
            <div>All Filters</div>
            <fa-icon [icon]="faTimes" class='close-btn text-gray-600 cursor-pointer' (click)="closeMobileFilter()"></fa-icon>
        </div>
        <div class="max-h-[70vh] filter-sections flex flex-col divide-y overflow-y-auto p-6">
            <div class="select-menu w-full active pb-6" *ngIf="valueBetsSports.length !== 1">
                <div class="label">
                    Sports
                </div>
                <!-- <div class="select-btn" (click)="toggleSportsDropdown()">
                    <span class="sBtn-text">{{ sportsDropdownLabel }}</span>
                    <fa-icon class="duration-300" [icon]="faChevronDown"></fa-icon>
                </div> -->
    
                <ul class="max-h-[250px] overflow-x-auto ml-0 mr-4">
                    <li class="flex h-8 justify-between cursor-pointer py-0 px-3 items-center gap-2 text-sm hover:bg-slate-200"
                        (click)="toggleAllFilterSportsWithDelay();">
                        <span class="option-text text-sm text-gray-600">
                            All {{ valueBetsSports.length }} sports
                        </span>
                        <fa-icon [hidden]="filterSports.length === valueBetsSports.length" [icon]="faSquare"
                            class="checkbox-empty text-sm text-gray-600"></fa-icon>
                        <fa-icon [hidden]="filterSports.length !== valueBetsSports.length" [icon]="faCheckSquare"
                            class='checkbox-checked text-highlight-100 text-sm'></fa-icon>
                    </li>
                    <li class="flex h-8 justify-between cursor-pointer py-0 px-3 items-center gap-2 text-sm hover:bg-slate-200"
                        *ngFor="let sport of valueBetsSports" (click)="toggleFilterSport(sport);sportsLabelupdate()">
                        <span class="option-text text-sm text-gray-600">
                            {{sport}}
                        </span>
                        <fa-icon [hidden]="filterSports.includes(sport)" [icon]="faSquare"
                            class="checkbox-empty text-sm text-gray-600"></fa-icon>
                        <fa-icon [hidden]="!filterSports.includes(sport)" [icon]="faCheckSquare"
                            class='checkbox-checked text-highlight-100 text-sm'></fa-icon>
                    </li>
                </ul>
            </div>
    
            <div class="select-menu bet-type w-full active" [class.py-6]="valueBetsSports.length !== 1" [class.pb-6]="valueBetsSports.length !== 1" >
                <div class="label">
                    Bet types
                </div>
                <!-- <div class="select-btn" (click)="toggleBetsDropdown()">
                    <span class="sBtn-text">{{ betTypesDropdownLabel }}</span>
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                </div> -->
                <ul class="ml-0 mr-4">
                    <li class="flex h-8 cursor-pointer py-0 px-3 items-center gap-2 text-sm justify-between hover:bg-slate-200" (click)="toggleAllFilterBetTypeWithDelay()">
                        <span class="option-text text-sm text-gray-600">
                            {{ filterBetTypes.length === betTypes.length ? 'Deselect All' : 'Select All' }}
                        </span>
                        <fa-icon [hidden]="filterBetTypes.length === betTypes.length" [icon]="faSquare"
                            class="checkbox-empty text-sm text-gray-600"></fa-icon>
                        <fa-icon [hidden]="filterBetTypes.length !== betTypes.length" [icon]="faCheckSquare"
                            class='checkbox-checked text-highlight-100 text-sm'></fa-icon>
                    </li>
                    <li class="flex h-8 cursor-pointer py-0 px-3 items-center gap-2 text-sm justify-between hover:bg-slate-200"
                        *ngFor="let betType of betTypes" (click)="toggleFilterBetType(betType);betTypesLabelupdate()">
                        <span *ngIf="betType !== 'other'" class="option-text text-sm text-gray-600">
                            {{localisationService.localeTerm(betType)}}
                        </span>
                        <span *ngIf="betType === 'other'" class="option-text text-sm text-gray-600">
                            Other
                        </span>
                        <fa-icon [hidden]="filterBetTypes.includes(betType)" [icon]="faSquare"
                            class="checkbox-empty text-sm text-gray-600"></fa-icon>
                        <fa-icon [hidden]="!filterBetTypes.includes(betType)" [icon]="faCheckSquare"
                            class='checkbox-checked text-highlight-100 text-sm'></fa-icon>
                    </li>
                </ul>
            </div>
            
            <div class="select-menu time-frame w-full py-6">
                <div class="label">
                    Timeframe
                </div>
                <div class="toggle-ctr mt-2">
                    <button (click)="setFilterTimeframe(24)" [class.active]="filterTimeframe === 24">
                        24 Hrs
                    </button>
                    <button (click)="setFilterTimeframe(72)" [class.active]="filterTimeframe === 72">
                        72 Hrs
                    </button>
                    <button (click)="setFilterTimeframe(168)" [class.active]="filterTimeframe === 168">
                        7 days
                    </button>
                </div>
            </div>
        </div>
        <div class="show-result-btn-group flex gap-4 w-full items-stretch mt-auto p-6">
            <button class="reset-btn w-1/3 sm:w-1/2" (click)="resetFilterOptions()">Clear all</button>
            <button class="show-result-btn w-2/3 sm:w-1/2" (click)="closeMobileFilter()">
               Show {{ displayedValuePlays }} bets
            </button>
        </div>
    </div>
</div>

