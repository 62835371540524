import { AfterViewInit, Component, ElementRef, Inject, OnInit, Optional, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';
import { MetaSetterService } from '../meta-setter.service';
import { Location, isPlatformBrowser } from '@angular/common'
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import { RESPONSE } from '../../../express.tokens'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { SwiperOptions } from 'swiper/types';
import { SwiperContainer } from 'swiper/element';
// install Swiper modules
SwiperCore.use([Navigation]);
@Component({
	selector: 'app-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ErrorPageComponent implements OnInit, AfterViewInit {
    swiperConfig: SwiperOptions = {
        navigation: {prevEl: '.carousel-prev-button', nextEl: '.carousel-next-button'},
        autoHeight: true,
        loop: true
    };

    browserMode = false;

    sliders = [
        { 
            link: "/best-bets",
            img: "https://imgix.cosmicjs.com/8a8c1140-536d-11ee-b710-9bd3d89f45f4-3fc14a4a154155333b7617c7d202af1c.jpeg?auto=format,compress&h=240", 
            title: "Best Bets", 
            description: "Our acclaimed Best Bets page is the ultimate resource for Dimers' best bets across a wide range of sports. Save time and bet with confidence as we curate the best value plays with the most favorable odds. The higher the edge, the better! It's no wonder this is our most popular page."
        },
        { 
            link: "/prop-bets",
            img: "https://imgix.cosmicjs.com/8a646500-536d-11ee-b710-9bd3d89f45f4-88b8e313c7b815c6ab84eff72b8f8352.jpeg?auto=format,compress&h=240", 
            title: "Prop Bets", 
            description: " Explore our Best Props page, where we analyze player props, curate the top picks with the best odds, and put them all at your fingertips. Take your betting game to the next level with our extensive collection of prop bets across a variety of sports. It's yet another favorite among Dimers users." 
        },
        {
            link: "/parlay-picker",
            img: "https://imgix.cosmicjs.com/8a8b4df0-536d-11ee-b710-9bd3d89f45f4-c4d533f790eeae6f113e9be7d8732e5e.jpeg?auto=format,compress&h=240", 
            title: "Parlay Picker", 
            description: "Create winning parlays in seconds with our intuitive Parlay Picker, where we harness the power of our proprietary data to assemble the best bets from various sports, constructing parlays with the maximum edge against the top odds available. Choose your settings and watch your parlays come to life." 
        }
    ];

    faChevronLeft = faChevronLeft;
    faChevronRight = faChevronRight;
    @ViewChild("swiper") swiperEl: ElementRef<SwiperContainer>;

  
	constructor(
        private metaSetter: MetaSetterService,
        private swUpdate: SwUpdate,
        private location:Location,
		@Optional() @Inject(RESPONSE) private response: any,
        @Inject(PLATFORM_ID) platformId: string,
    ) { 
        this.browserMode = isPlatformBrowser(platformId);
    }

    goBackButtonClick() {
        this.location.back();
    }

	ngOnInit(): void {

		if (this.response) {
			this.response.status(404);
		}

		this.metaSetter.setMeta({
			title: "Dimers",
			description: "No more watching from the sidelines. Dimers gives sports fans the news, tools & analysis to Make the Play.",
			// metaImageURL: "https://cdn.ciphersports.io/images/dimers-site/generic_meta.jpeg",
		})

		if (this.swUpdate.isEnabled && typeof window !== "undefined") {
			this.swUpdate.versionUpdates
				.pipe(
					filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
				)
				.subscribe(() => {
					window.location.reload();
				});
		}
	}

    ngAfterViewInit(): void {
        Object.assign(this.swiperEl.nativeElement, this.swiperConfig);

        if (this.browserMode) {
            this.swiperEl.nativeElement.initialize();
        }
    }

}
