import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BlogService } from 'src/app/blog.service';

@Component({
  selector: 'app-visit-our-partners',
  templateUrl: './visit-our-partners.component.html',
  styleUrls: ['./visit-our-partners.component.scss']
})
export class VisitOurPartnersComponent implements OnInit {

    browserMode = false;
    sportsbooks:any;
    loading = false;
    loadingError = false;

    constructor(
        private blogService: BlogService,
        @Inject(PLATFORM_ID) platformId: string,
        @Inject(DOCUMENT) public document: Document,
    ) {
        this.browserMode = isPlatformBrowser(platformId);
    }

    ngOnInit() {
        this.retrieveDataFromCosmic();
    }


    retrieveDataFromCosmic(): void {
        this.loading = true;
        this.loadingError = false;

        this.blogService.getSportsBooks().subscribe(data => {
            this.sportsbooks = data.filter(sportsbook => sportsbook.metadata?.footer_partner?.visible === true);
            this.sportsbooks.sort((a,b)=> a.metadata?.footer_partner?.rank - b.metadata?.footer_partner?.rank);
            this.loading = false;
        }, err => {
            console.log(err);
            this.loading = false;
            this.loadingError = true;
        });

    }

}
