<div class="hidden md:flex md:flex-row flex-wrap gap-2 items-center">
    <div>
        <img [src]="author.metadata.author_thumbnail.url" alt="profile-img" width="48"
            height="48"
            class="rounded-full max-w-full h-auto align-middle border border-yellow-400 shadow-md">
    </div>
    <div class="flex flex-col ">
        <div class="text-sm font-medium">
            Written by <a class="underline text-black hover:text-highlight-100"
                href="/news/author/{{author.slug}}"
                routerLink="/news/author/{{author.slug}}">{{author.title}}</a>,
            {{author.metadata.title}}
        </div>
        <div class="text-sm font-medium flex flex-row gap-2">
            <div>
                Updated on {{reviewTime | date:'mediumDate'}}
            </div>
            <span>&#8226;</span>
            <div class="flex flex-row gap-1 items-baseline">
                <fa-icon class="text-green-600" [icon]="faClipboardCheck"></fa-icon>
                Fact checked
            </div>
            <span>&#8226;</span>
            <div>
                Reviewed by <a class="underline text-black hover:text-highlight-100"
                    href="/news/author/nick-slade" routerLink="/news/author/nick-slade">Nick Slade</a>,
                Chief Content Officer
            </div>
        </div>
    </div>
</div>


<div class="flex flex-col gap-2 md:hidden">
    <div class="flex flex-row gap-2 items-center">
        <div>
            <img [src]="author.metadata.author_thumbnail.url" alt="profile-img"
                width="48" height="48"
                class="rounded-full max-w-full h-auto align-middle border border-yellow-400 shadow-md">
        </div>
        <div class="text-xs font-medium">
            Written by <a class="underline text-black hover:text-highlight-100"
                href="/news/author/{{author.slug}}"
                routerLink="/news/author/{{author.slug}}">{{author.title}}</a>
            <br /> {{author.metadata.title}}
        </div>
    </div>
    <div class="text-xs font-medium ">
        <div class="flex flex-row gap-2">
            <div>
                Updated on {{reviewTime | date:'mediumDate'}}
            </div>
            <div class="flex flex-row gap-1 items-baseline">
                <fa-icon class="text-green-600" [icon]="faClipboardCheck"></fa-icon>
                Fact checked
            </div>
        </div>
        <div>
            Reviewed by <a class="underline text-black hover:text-highlight-100"
                href="/news/author/nick-slade" routerLink="/news/author/nick-slade">Nick Slade</a>, Chief
            Content Officer
        </div>
    </div>
</div>