<div class="shortlist-button" [class.in-list]="shortlistService.matchInShortlist(this.matchID)" (click)="toggleMatchInShortlist()">
    <div class="shortlist-icon">
        <span *ngIf="!shortlistService.matchInShortlist(this.matchID)">
            <fa-icon [icon]="faStarReg"
                attr.aria-label="Add {{ localisationService.localeTerm((sport | lowercase) === 'ten' ? 'match_tennis' : 'match', 'title-case') }} to {{ localisationService.localeTerm('shortlist', 'title-case') }}"
                role="button"
            ></fa-icon>
        </span>
        <span *ngIf="shortlistService.matchInShortlist(this.matchID)">
            <fa-icon [icon]="faStarSolid"
                attr.aria-label="Remove {{ localisationService.localeTerm((sport | lowercase) === 'ten' ? 'match_tennis' : 'match', 'title-case') }} from {{ localisationService.localeTerm('shortlist', 'title-case') }}"
                role="button"
            ></fa-icon>
        </span>
        <div class="shortlist-info-popup bg-white text-gray-900 text-xs font-medium" (click)="$event.stopPropagation()">
            <div class="arrow-point"></div>
            <span *ngIf="shortlistService.matchInShortlist(this.matchID)">Remove this {{ localisationService.localeTerm((sport | lowercase) === 'ten' ? 'match_tennis' : 'match', '') }} from My {{ localisationService.localeTerm('shortlist', 'title-case') }}</span>
            <span *ngIf="!shortlistService.matchInShortlist(this.matchID)">Add this {{ localisationService.localeTerm((sport | lowercase) === 'ten' ? 'match_tennis' : 'match', '') }} to My {{ localisationService.localeTerm('shortlist', 'title-case') }}</span>
            <button *ngIf="shortlistService.matchInShortlist(this.matchID)" class="bg-highlight-100 text-white text-sm font-normal h-9 rounded ml-2 px-2" routerLink="/my-account">
                See My {{ localisationService.localeTerm('shortlist', 'title-case') }}
            </button>
        </div>
    </div>
</div>
<ng-container *ngIf="mobile">
    <div class="modal-overlay" *ngIf="confirmPopupVisible"> 
        <div class="modal-dialog">
            <div class="close-button" (click)="closePopup()">
                <fa-icon [icon]="faTimes" aria-label="Close"></fa-icon>
            </div>
            <div class="text-sm font-semibold text-gray-900" *ngIf="this.shortlistService.matchInShortlist(this.matchID)">
                <span class="capitalize">{{ localisationService.localeTerm((sport | lowercase) === 'ten' ? 'match_tennis' : 'match', '') }}</span> added to My {{ localisationService.localeTerm('shortlist', 'title-case') }}
            </div>
            <div class="text-sm font-semibold text-gray-900" *ngIf="!this.shortlistService.matchInShortlist(this.matchID)">
                Removed from My {{ localisationService.localeTerm('shortlist', 'title-case') }}
            </div>
            <button class="bg-highlight-100 text-white text-sm font-normal w-full h-9 rounded mt-4"  routerLink="/my-account">
                See My {{ localisationService.localeTerm('shortlist', 'title-case') }}
            </button>
        </div>
    </div>
</ng-container>
