import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-article-image-container',
  templateUrl: './article-image-container.component.html',
  styleUrls: ['./article-image-container.component.scss']
})
export class ArticleImageContainerComponent {
  @Input() imageSrc?: string;
  @Input() lazy?: boolean = true;
  @Input() imageAlt?: string;
  @Input() unrounded?: boolean = false;
  constructor() { }

}
