import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Sport } from 'src/app/sport.model';
import { SPORTS } from 'src/app/sports';

@Component({
  selector: 'app-sport-icon',
  templateUrl: './sport-icon.component.html',
  styleUrls: ['./sport-icon.component.scss']
})
export class SportIconComponent implements OnChanges {
  @Input() code: String;
  @Input() colour: String;
  sport: Sport;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).includes("code")) {
      const sportCodeChange = changes["code"];
      if (SPORTS.some(s => s.code === sportCodeChange.currentValue)) {
        this.sport = SPORTS.find(s => s.code === sportCodeChange.currentValue);
      } else {
        console.warn(`SportIconComponent constructor failed - sport code ${sportCodeChange.currentValue} not found`);
      }
    }
  }

}
