import { Component, Inject, PLATFORM_ID, Input, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AuthHelperService } from 'src/app/auth-helper.service';

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { faStar as faStarSolid, faStarHalfStroke,faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRight} from '@fortawesome/pro-light-svg-icons';


@Component({
  selector: 'app-masthead-offers',
  templateUrl: './masthead-offers.component.html',
  styleUrls: ['./masthead-offers.component.scss']
})
export class MastheadOffersComponent implements OnInit{
    @Input() status?:string;
    @Input() offers?:any;
    @Input() stateCode?:string;
    @Input() title?: string;
    @Input() subTitle?: string;
    faStarFull = faStarSolid;
    faStarHalf = faStarHalfStroke;
    faArrowUpRight = faArrowUpRight;
    offerDropdownOpen: boolean[] = [];
    faChevronDown = faChevronDown; 
    faChevronUp = faChevronUp;
    browserMode = false;


    constructor(
        public authHelper: AuthHelperService,
        private gtmService: GoogleTagManagerService,
        @Inject(PLATFORM_ID) platformId: string,
        @Inject(DOCUMENT) public document: Document,
    ) {
        this.browserMode = isPlatformBrowser(platformId);
    }

    ngOnInit() {
        if (this.browserMode) {
            Object.keys(this.offers).forEach((item, index) => {
                if(item ==="Caesars Sportsbook"){
                    this.offerDropdownOpen['caesars'] = true; 
                }
              });
        }
    }


    redirectBookiePromoLink(url: string, queryParams: Record<string, string>, bookmaker: any) {
        // if (!this.excludeBookies(bookmaker?.slug || "")) {
        //     queryParams.c = (this.router.url.split("/")[1] || 'home') + (
        //         this.authHelper.user
        //             ? ('|' + this.authHelper.user.id)
        //             : '|visitor'
        //     );
        // }
        if (bookmaker.slug === 'underdog-fantasy') {
            delete queryParams['promo']
        }
        const combinedUrl = url + (url.includes("?") ? "&" : "?") + new URLSearchParams(queryParams).toString();
        if (this.browserMode) {
            const gtmTag = {
                event: "exit",
                event_category: "best-book",
                event_label: bookmaker.slug,
                event_location: "best-book-masthead"  
            };

            this.gtmService.pushTag(gtmTag)
                .then(() => {
                    window.open(combinedUrl, "_self");
                })
                .catch(e => {
                    console.error("Google Tag Manager Script failed: " + e)
                    window.open(combinedUrl, "_self");
                });
        }

        //remove smartlook tracking functionality
        // const eventName = 'OfferClaimNowButtonClicked';
        // let property = {
        // "offer-placement-location": "best-book-masthead",
        // "page-path": this.document?.location.pathname,
        // 'offer-bookmaker': bookmaker.slug,
        // "offer-state-code": this.stateCode
        // };
        // window.smartlook('track', eventName, property);
    }

    // excludeBookies(bookie: string): boolean {
    //     bookie = bookie.replace(/\s/g, '').toLowerCase()
    //     switch (bookie) {
    //     case 'bet365':
    //     case 'bet_365':
    //         return true
    //     case 'pointsbet':
    //         return true
    //     case 'betmgm':
    //         return true
    //     default:
    //         return false
    //     }
    // }

        //sorting offers based on sportsbook index field
        sortingOffers = (a, b) => {
            return a.value[0].metadata.sportsbook[0].metadata.index > b.value[0].metadata.sportsbook[0].metadata.index ? 1 : -1;
        }
    

        toggleOfferDropdown(event, index:number, slug:string): void {
            event.stopPropagation();

            this.offerDropdownOpen[slug] = !this.offerDropdownOpen[slug];
            //remove smartlook tracking functionality
            // const eventName = 'HowToClaimButtonClicked';
            // let property = {
            // "offer-placement-location": "best-book-masthead",
            // "page-path": this.document?.location.pathname,
            // 'offer-bookmaker': slug,
            // "offer-state-code": this.stateCode
            // };
            // window.smartlook('track', eventName, property);

        }

        //remove smartlook tracking functionality
        // sendTosmartLook(event, slug:string) {
        //     event.stopPropagation();
    
        //     const eventName = 'ReadReviewButtonClicked';
        //     let property = {
        //     "offer-placement-location": "best-book-masthead",
        //     "page-path": this.document?.location.pathname,
        //     'offer-bookmaker': slug,
        //     "offer-state-code": this.stateCode
        //     };
        //     window.smartlook('track', eventName, property);
        // }
}
