<div class="py-4 px-6 rounded-lg bg-navy-100 flex flex-col gap-4">
    <div class="flex gap-2">
        <div>
            <img [src]="author.metadata.author_thumbnail.url" alt="profile-img" width="48" height="48" class="rounded-full max-w-full h-auto align-middle border border-yellow-400 shadow-md">
        </div>
        <div>
            <div class="font-bold text-base text-black" >
                <a class="underline text-black hover:text-highlight-100" href="/news/author/{{author.slug}}" routerLink="/news/author/{{author.slug}}">{{author.title}}</a>
            </div>
            <div class="font-medium text-sm text-neutral-400">
                {{author.metadata.title}}
            </div>
        </div>
    </div>
    <div class="text-base font-medium text-neutral-600" [innerHTML]="author.content | trustCosmic">
    </div>
</div>