import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-email-verify-required-page',
  templateUrl: './email-verify-required-page.component.html',
  styleUrls: ['./email-verify-required-page.component.scss']
})
export class EmailVerifyRequiredPageComponent implements OnInit{

  originalTarget: string;
  sending = false;
  sendError = false;

  userLoading:boolean = true;
  userError:boolean = false;

  email:string;
  sub:string;
  countdown: number = 0;
  countdownStarted: boolean = false;
  intervalId: any;

  constructor(
    route: ActivatedRoute,
    private http: HttpClient,
    public authHelper: AuthHelperService,

    
  ) {
    this.originalTarget = "/" + route.snapshot.queryParamMap.get("dest");
  }

  phase: "pre-send" | "post-send" = "pre-send";

  ngOnInit(): void {
    this.userLoading = true;
    this.userError = false;
    this.authHelper.detailedUser$.subscribe(
        {
            next: (item) => {
                this.userLoading = false;
                this.email = item?.email;
                this.sub = item?.sub;
            },
            error: () => {
              this.userError = true;
            },
          }
    );
  }

  startCountdown(seconds: number) {
    this.countdown = seconds;
    this.countdownStarted = true;

    this.intervalId = setInterval(() => {
      this.countdown--;
      
      if (this.countdown <= 0) {
        clearInterval(this.intervalId);
      }
    }, 1000);
  }

  sendVerifyEmail() {
    // TODO
    this.sendError = false;
    this.sending = true;
      this.http.post(`${environment.dimersProApi}/${this.sub}/reverify`, {})
      .subscribe(
        {
          next: (response) => {
            this.sending = false;
            this.phase = "post-send";
             // Start the countdown
            this.startCountdown(60);
          },
          error: () => {
            // TODO
            this.sendError = true;
            this.sending = false;
          },
        }
      )
  }
}
