<div class="partners max-w-7xl px-[10px] ml-auto mr-auto pb-12" id="partners" *ngIf="!loading && !loadingError">
    <div class="text-2xl font-semibold capitalize text-center">
      Our partners
    </div>
    <ng-container *ngIf="this.sportsbooks.length > 6">
        <div class="logo-slider mt-4">
            <div class="flex items-center">
                <ng-container *ngFor="let item of sportsbooks">
                    <img [src]="item.metadata.promo_logo.imgix_url + '?auto=format,compress&h=48'" loading="lazy"
                        class="h-12 min-w-[130px]" [class]="item.title.toLowerCase()" [alt]="item.title">
                </ng-container>
            </div>
    
            <!-- Second, identical row of logos to complete seamless scroll animation loop -->
            <div class="flex items-center">
                <ng-container *ngFor="let item of sportsbooks">
                    <img [src]="item.metadata.promo_logo.imgix_url + '?auto=format,compress&h=48'" loading="lazy"
                        class="h-12 min-w-[130px]" [class]="item.title.toLowerCase()" [alt]="item.title">
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="this.sportsbooks.length <= 6">
        <div class="mt-4">
            <div class="flex items-center gap-10 sm:gap-20 justify-center flex-wrap">
                <ng-container *ngFor="let item of sportsbooks">
                    <img [src]="item.metadata.promo_logo.imgix_url + '?auto=format,compress&h=48'" loading="lazy"
                        class="h-12" [class]="item.title.toLowerCase()" [alt]="item.title">
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>