import { State } from "./state.model";

export const STATES: Array<State> = [
    {
        name:"Arizona",
        legalOnlineBetting: true
    },
    {
        name:"Arkansas",
        legalOnlineBetting: true
    },
    {
        name:"Colorado",
        legalOnlineBetting: true
    },
    {
        name:"Connecticut",
        legalOnlineBetting: true
    },
    {
        name:"Illinois",
        legalOnlineBetting: true
    },
    {
        name:"Indiana",
        legalOnlineBetting: true
    },
    {
        name:"Iowa",
        legalOnlineBetting: true
    },
    {
        name:"Kansas",
        legalOnlineBetting: true
    },
    {
        name:"Kentucky",
        legalOnlineBetting: true
    },
    {
        name:"Louisiana",
        legalOnlineBetting: true
    },
    {
        name:"Maryland",
        legalOnlineBetting: true
    },
    {
        name:"Massachusetts",
        legalOnlineBetting: true
    },
    {
        name:"Michigan",
        legalOnlineBetting: true
    },
    {
        name:"Nevada",
        legalOnlineBetting: true
    },
    {
        name:"New Hampshire",
        legalOnlineBetting: true
    },
    {
        name:"New Jersey",
        legalOnlineBetting: true
    },
    {
        name:"New York",
        legalOnlineBetting: true
    },
    {
        name:"Ohio",
        legalOnlineBetting: true
    },
    {
        name:"Oregon",
        legalOnlineBetting: true
    },
    {
        name:"Pennsylvania",
        legalOnlineBetting: true
    },
    {
        name:"Rhode Island",
        legalOnlineBetting: true
    },
    {
        name:"Tennessee",
        legalOnlineBetting: true
    },
    {
        name:"Vermont",
        legalOnlineBetting: true
    },
    {
        name:"Virginia",
        legalOnlineBetting: true
    },
    {
        name:"Washington D.C.",
        legalOnlineBetting: true
    },
    {
        name:"West Virginia",
        legalOnlineBetting: true
    },
    {
        name:"Wyoming",
        legalOnlineBetting: true
    },
    {
        name:"Alabama",
        legalOnlineBetting: false
    },
    {
        name:"Alaska",
        legalOnlineBetting: false
    },
    {
        name:"California",
        legalOnlineBetting: false
    },
    {
        name:"Delaware",
        legalOnlineBetting: false
    },
    {
        name:"Florida",
        legalOnlineBetting: false
    },
    {
        name:"Georgia",
        legalOnlineBetting: false
    },
    {
        name:"Hawaii",
        legalOnlineBetting: false
    },
    {
        name:"Idaho",
        legalOnlineBetting: false
    },
    {
        name:"Maine",
        legalOnlineBetting: false
    },
    {
        name:"Minnesota",
        legalOnlineBetting: false
    },
    {
        name:"Mississippi",
        legalOnlineBetting: false
    },
    {
        name:"Missouri",
        legalOnlineBetting: false
    },
    {
        name:"Montana",
        legalOnlineBetting: false
    },
    {
        name:"Nebraska",
        legalOnlineBetting: false
    },
    {
        name:"New Mexico",
        legalOnlineBetting: false
    },
    {
        name:"North Carolina",
        legalOnlineBetting: false
    },
    {
        name:"North Dakota",
        legalOnlineBetting: false
    },
    {
        name:"Oklahoma",
        legalOnlineBetting: false
    },
    {
        name:"South Carolina",
        legalOnlineBetting: false
    },
    {
        name:"South Dakota",
        legalOnlineBetting: false
    },
    {
        name:"Texas",
        legalOnlineBetting: false
    },
    {
        name:"Utah",
        legalOnlineBetting: false
    },
    {
        name:"Washington",
        legalOnlineBetting: false
    },
    {
        name:"Wisconsin",
        legalOnlineBetting: false
    },
]