import { AfterContentChecked, Component, ElementRef, ErrorHandler, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit,
	SimpleChanges, ViewChild, ViewEncapsulation} from "@angular/core";
import { Router } from "@angular/router";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { parseJSON } from "date-fns";
import { catchError, map, retry, tap } from "rxjs/operators";
import { WebSocketSubject } from "rxjs/webSocket";
import { BetService } from "src/app/bet.service";
import { BOOKMAKERS } from "src/app/bookmakers";
import { GeneralService } from "src/app/general.service";
import { LocalisationService } from "src/app/localisation.service";
import { MatchLiveData } from "src/app/match-live-data.model";
import { IsLivePipe } from "src/app/match-pipes/is-live.pipe";
import { Match } from "src/app/match.model";
import { MatchService } from "src/app/match.service";
import { SportDataService } from "src/app/sport-data.service";

@Component({
	selector: "app-game-bar-block",
	templateUrl: "./game-bar-block.component.html",
	styleUrls: ["./game-bar-block.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class GameBarBlockComponent implements OnInit, OnDestroy, OnChanges, AfterContentChecked {

	@Input() sports: Array<string>;
	@Input() forwardHours?: number;
	@Input() backwardHours?: number;
	@ViewChild("blockBody") blockBody: ElementRef;

	gameBarSports: Array<string> = [];
	gamesError = false;
	gamesLoading = true;
	liveDataTimeout = null;
	matches: Array<Match> = [];
	midGrab = false;
	selectedSport = "all";
	shiftElementCount = 0;
	shiftPixelCount = 0;
	blockInitStarted = false;
	stripInteractedWith = false;
	defaultVisibleSportCount = undefined;

	gameBarSportOptions: Array<string> = [];

	liveSocketError = false;
	liveDataSocket$: WebSocketSubject<any>;

	displayedMatchesDict: Record<string, Array<Match>> = {};

	grabStartPos: Record<string, number> = {left: 0, x: 0};
	displayMaximumWidths: Record<string, number> = {
		"x-compact": 350,
		compact: 500,
		"less-compact": 600,
		medium: 700,
		large: 800,
	};

	// list of codes of sports which are in "Championship Mode" - that is, they're in the
	// final phase of their postseason or equivalent
	// e.g. MLB is in Championship Mode during the World Series,
	// NFL is in Championship Mode between the Conference Championships and the Super Bowl
	championshipModeLeagues: Array<string> = [];

	faAngleLeft = faAngleLeft;
	faAngleRight = faAngleRight;

	grabMoveBound = this.grabMove.bind(this);
	endGrabBound = this.endGrab.bind(this);

	constructor(
		private errorHandler: ErrorHandler,
		public matchService: MatchService,
		public sportDataService: SportDataService,
		public generalService: GeneralService,
		public betService: BetService,
		private localisationService: LocalisationService,
		private isLivePipe: IsLivePipe,
		private router: Router,
		private el: ElementRef,
		private ngZone: NgZone,
	) {}

	prepareBlock(): void {
		this.blockInitStarted = true;
		// this.generalService.initialiseBlock(this.el);

		this.gameBarSports = this.sports.map(s => s.toUpperCase());

		this.matchService.getGeoData().then(() => {
			this.retrieveGames();
		}, () => {
			this.retrieveGames();
		});
	}

	@HostListener("click", ["$event"])
	checkForInternalLinkClick(event: MouseEvent): void {
		this.generalService.checkForInternalLinkClick(event);
	}

	ngOnInit(): void {
		if (this.sports && !this.blockInitStarted) {
			this.prepareBlock();
		}

	}

	ngAfterContentChecked(): void {
		// get element which defines the visible section of the strip
		const trackElement = this.el.nativeElement.querySelector(".game-bar-games");

		if (trackElement) {
			// within that boundary element, find all sport groups
			const groupsAhead = Array.from(trackElement.querySelectorAll(".game-sport-group")) as Array<HTMLElement>;

			if (groupsAhead.length === 0) {
				this.defaultVisibleSportCount = undefined;
			} else {
				// tally the combined width of these sport groups until the combined width exceeds the visible width of the track
				let widthTotal = 0;
				let index = 0;
				do {
					widthTotal += groupsAhead[index].offsetWidth;

					index++;
				} while (widthTotal <= trackElement.offsetWidth && index < groupsAhead.length);
				
				this.defaultVisibleSportCount = index;
			}
		} else {
			this.defaultVisibleSportCount = undefined;
		}
		

		
		

	}


	ngOnChanges(changes: SimpleChanges): void {
		if (!this.blockInitStarted && changes.sports?.currentValue) {
			this.prepareBlock();
		}
	}

	ngOnDestroy(): void {
		if (this.liveDataTimeout) {
			clearTimeout(this.liveDataTimeout);
			this.liveDataTimeout = null;
		}

		// close match data socket
		if (this.liveDataSocket$ && !this.liveDataSocket$.closed) {
			this.liveDataSocket$.complete();
		}
	}

	calculateGameBarSportOptions(): void {
		this.gameBarSportOptions = this.gameBarSports
			.filter((sport: string) =>
				this.matches.some(this.matchService.matchFitsFilter(sport,
				this.forwardHours,
				this.backwardHours)))
			.sort((a,b) => {
				const topAGame = this.matches.filter(this.matchService.matchFitsFilter(a,
					this.forwardHours,
					this.backwardHours))
					.sort((ia,ib) => this.matchService.gameDisplaySort(ia,ib))[0];
				const topBGame = this.matches.filter(this.matchService.matchFitsFilter(b,
					this.forwardHours,
					this.backwardHours))
					.sort((ia,ib) => this.matchService.gameDisplaySort(ia,ib))[0];

				return this.matchService.gameDisplaySort(topAGame, topBGame, true);
			});
	}

	shiftLeft(): void {
		if (this.shiftElementCount > 0) {
			this.stripInteractedWith = true;
			const trackElement = this.el.nativeElement.querySelector("#si-game-track");
			const trackChildren = trackElement.querySelectorAll(".sport-group-header, .game-link, .bookie-attr");
			const incrementLength = (trackChildren[this.shiftElementCount - 1] as HTMLElement).offsetWidth;
			this.shiftElementCount--;
			trackElement.scroll({
				left: trackElement.scrollLeft - incrementLength,
				behavior: "smooth"
			});
		}
	}

	shiftRight(): void {
		this.stripInteractedWith = true;
		const trackElement = this.el.nativeElement.querySelector("#si-game-track");
		const trackChildren = trackElement.querySelectorAll(".sport-group-header, .game-link, .bookie-attr");
		if (this.shiftElementCount < (trackChildren.length - 1)) {
			const incrementLength = (trackChildren[this.shiftElementCount] as HTMLElement).offsetWidth;
			if ((trackElement.scrollLeft + incrementLength) <= trackElement.scrollWidth) {
				this.shiftElementCount++;
				trackElement.scroll({
					left: trackElement.scrollLeft + incrementLength,
					behavior: "smooth"
				});
			}
		}
	}

	startGrab(e: PointerEvent): void {
		this.stripInteractedWith = true;
		const trackElement = this.el.nativeElement.querySelector("#si-game-track");
		this.grabStartPos = {left: trackElement.scrollLeft, x: e.clientX};
		if (typeof window !== "undefined" && typeof document !== "undefined") {
			document.addEventListener("pointermove", this.grabMoveBound);
			document.addEventListener("pointerup", this.endGrabBound);
		}
	}

	grabMove(e: PointerEvent): void {
		// calculate difference in pixels between new position and starting position of track
		const dx = e.clientX - this.grabStartPos.x;

		// if track has been moved more than 5px either direction, switch to mid-grab mode
		// (this is permanent for that grab, so reverting to start should not result in mid-grab being turned off)
		if (Math.abs(dx) > 5) {
			this.midGrab = true;
		}

		// set new position of track
		const trackElement = this.el.nativeElement.querySelector("#si-game-track");
		trackElement.scrollLeft = this.grabStartPos.left - dx;
	}

	endGrab(e: PointerEvent): void {
		// if the action was a straight click instead of a drag, go through to the link of that tile
		if (!this.midGrab && (e.target as Element).closest("[href]") !== null && e.button === 0) {
			const parentTile = (e.target as Element).closest("[href]");
			this.router.navigateByUrl((e.target as Element).closest("[href]").getAttribute("href"));

            // if(this.inShapedTest) {
            //     window.analytics.track("ScoreStrip match Clicked", {
            //         "tile-match": parentTile.getAttribute("data-match-id"),
            //         "tile-starting-page": document?.location.pathname,
            //         "tile-sport": parentTile.getAttribute("data-sport"),
            //         "tile-status": parentTile.getAttribute("data-match-status"),
            //         "in-experiment": this.inShapedTest,
            //         "experiment-on": this.usesShaped,
            //     });
            // }

		}

		this.midGrab = false;

		let cumulativeWidth = 0;
		let i = 0;

		const trackElement = this.el.nativeElement.querySelector("#si-game-track");
		const trackChildren = trackElement.querySelectorAll(".sport-group-header, .game-link, .bookie-attr");

		while (i < trackChildren.length && cumulativeWidth <= trackElement.scrollLeft) {
			i += 1;
			cumulativeWidth += (trackChildren[i] as HTMLElement).offsetWidth;
		}

		this.shiftElementCount = i - 1;

		document.removeEventListener("pointermove", this.grabMoveBound);
		document.removeEventListener("pointerup", this.endGrabBound);
	}

	tileClick(e: MouseEvent): void {
		// don't let the link be accidentally clicked through to on click-and-drags (genuine clicks will be handled by endGrab)
		e.preventDefault();
	}

	checkForNewMatches(): void {
		let bookmakerList: Array<string> = [];
		bookmakerList = BOOKMAKERS;
		this.sportDataService.getUpcomingGames(this.gameBarSports, true, true, bookmakerList, this.localisationService.getLocaleObject().sportExclusiveBookmakers)
			.subscribe(matches => {
				const existingMatchList: Match[] = this.matches.slice();
				const newMatchList: Match[] = matches.filter((g: Match) => this.gameBarSports.includes(g.MatchData.Sport.toUpperCase()));

				newMatchList.forEach(match => {
					if (!existingMatchList.some(m => m.MatchData.SIMatchID === match.MatchData.SIMatchID)) {
						match.MatchData.UserDate = parseJSON(match.MatchData.Date);
						this.betService.populateTileBettingInfo(match);
						existingMatchList.push(match);
					}
				});

				existingMatchList.sort((a,b) => this.matchService.gameDisplaySort(a,b));

				this.matches = existingMatchList;

				// if the current selected sport is no longer available because its games have dropped off, revert to showing All games
				// if (this.selectedSport !== "all" && !existingMatchList.some(m => m.MatchData.Sport.toLowerCase() === this.selectedSport)) {
				// 	this.selectedSport = "all";
				// }
				if (typeof window !== "undefined") {
					this.ngZone.runOutsideAngular(() => {
						this.liveDataTimeout = setTimeout(() => this.ngZone.run(() => this.checkForNewMatches()), 60000);
					});
				}
			}, (err) => {
				// if (err !== "API_CALL_FAILED") {
				// 	this.errorHandler.handleError(err);
				// }
			});
	}

	updateLiveData(newRow: MatchLiveData): void {
		// console.log("[Score Strip Block Socket] New row arrived for " + newRow.SIMatchID + ":");
		// console.log(newRow);
		if (newRow.stat_type !== "detailed") {
			const existingMatchList: Match[] = this.matches.slice();

			if (existingMatchList.some(m => m.MatchData.SIMatchID === newRow.SIMatchID)) {
				const oldMatchData = existingMatchList.find(m => m.MatchData.SIMatchID === newRow.SIMatchID);
				if (oldMatchData.LiveData !== newRow) {
					if (oldMatchData.LiveData && newRow && newRow.sr_event_sequence >= oldMatchData.LiveData.sr_event_sequence) {
						if (oldMatchData.LiveData.homeScore !== newRow.homeScore) {
							oldMatchData.homeScoreJustChanged = true;
							setTimeout(() => oldMatchData.homeScoreJustChanged = false, 1000);
						}

						if (oldMatchData.LiveData.awayScore !== newRow.awayScore) {
							oldMatchData.awayScoreJustChanged = true;
							setTimeout(() => oldMatchData.awayScoreJustChanged = false, 1000);
						}

					}

					oldMatchData.LiveData = newRow;
				}
			}

			existingMatchList.sort((a,b) => this.matchService.gameDisplaySort(a,b));

			this.matches = existingMatchList;

			this.calculateGameBarSportOptions();

			this.calculateDisplayedMatches();

			// if the current selected sport is no longer available because its games have dropped off, revert to showing All games
			// if (this.selectedSport !== "all" && !existingMatchList.some(m => m.MatchData.Sport.toLowerCase() === this.selectedSport)) {
			// 	this.selectedSport = "all";
			// }
		}
	}

	retrieveGames(): void {
		this.gamesLoading = true;
		this.gamesError = false;

		let bookmakerList: Array<string> = [];
		bookmakerList = BOOKMAKERS;

		this.sportDataService.getUpcomingGames(this.gameBarSports, true, true, bookmakerList, this.localisationService.getLocaleObject().sportExclusiveBookmakers)
			.subscribe(matches => {
				this.matches = matches.filter(g => this.gameBarSports.includes(g.MatchData.Sport.toUpperCase()));


				this.matches.forEach((match: Match) => {
					match.MatchData.UserDate = parseJSON(match.MatchData.Date);
					match.homeScoreJustChanged = false;
					match.awayScoreJustChanged = false;
					this.betService.populateTileBettingInfo(match);
				});

				this.matches.sort((a,b) => this.matchService.gameDisplaySort(a,b));

				this.calculateGameBarSportOptions();

				this.calculateDisplayedMatches();

				this.gamesLoading = false;

				if (typeof window !== "undefined") {
					this.ngZone.runOutsideAngular(() => {
						this.liveDataTimeout = setTimeout(() => this.ngZone.run(() => this.checkForNewMatches()), 60000);
						this.connectToLiveStream();
					});
				}
			}, (err) => {
				// if (err !== "API_CALL_FAILED") {
				// 	this.errorHandler.handleError(err);
				// }
				this.gamesError = true;
			});
	}

	reloadGames(): void {
		this.retrieveGames();
	}

	calculateDisplayedMatches(): void {
		const maxNonLiveTiles = 4;
		this.gameBarSportOptions.forEach(s => {
			// find all games for this sport which fit the time filters
			const displayEligibleMatches = this.matches.filter(m =>
				this.matchService.matchFitsFilter(s,
					this.forwardHours,
					this.backwardHours)(m)
			)

			// if there are more live games than the listed maximum tiles per sport, show all live games
			// (that is, extend the tile limit)
			if (displayEligibleMatches.filter(m => this.isLivePipe.transform(m)).length > maxNonLiveTiles) {
				this.displayedMatchesDict[s] = displayEligibleMatches
					.filter(m => this.isLivePipe.transform(m))
					.sort((a,b) => this.matchService.gameDisplaySort(a,b));
			}
			// otherwise, trim the list of matches to the supplied tile maximum, after sorting for prominence
			else {
				this.displayedMatchesDict[s] = displayEligibleMatches
					.sort((a,b) => this.matchService.gameDisplaySort(a,b))
					.slice(0, maxNonLiveTiles);
			}
		});

		// if (!this.configurationObject.groupMatchesBySport) {
		// 	this.displayedMatchesDict.all = this.matches.filter(m =>
		// 		this.matchService.matchFitsFilter("all")(m)
		// 	).sort((a,b) => this.matchService.gameDisplaySort(a,b))
		// 	.slice(0, 15);
		// }
	}

	connectToLiveStream = () => {
		this.liveDataSocket$ = this.sportDataService.connectToUpcomingPushStream(this.gameBarSports);
		// console.log("About to connect to socket (Score Strip Block)");
		const liveData$ = this.liveDataSocket$.pipe(
			map((row: MatchLiveData) => {
				this.ngZone.run(() => {
					this.updateLiveData(row);
				})
				return row;
			}),
			retry({
				delay: 3000,
			}),
			tap({
				error: error => this.errorHandler.handleError("[Score Strip Block Socket] Error:" +
					JSON.stringify(error, ["message", "arguments", "type", "name"])),
				complete: () => {
					// console.log("[Score Strip Block Socket] Connection Closed");
					this.liveSocketError = true;
				}
			})
		);

		liveData$.subscribe();
	};

}