import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-author-section',
  templateUrl: './author-section.component.html',
  styleUrls: ['./author-section.component.scss']
})
export class AuthorSectionComponent {

    @Input() author?:Record<string, any>;

}
