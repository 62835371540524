import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-flag-icon-pair',
  templateUrl: './flag-icon-pair.component.html',
  styleUrls: ['./flag-icon-pair.component.scss']
})
export class FlagIconPairComponent {
  @Input() backCountryCode: string;
  @Input() frontCountryCode: string;
}
