import { Pipe, PipeTransform } from '@angular/core';
import { Match } from '../match.model';
import { ScoreStripEntry } from '../score-strip-entry.model';
import { SPORTS } from '../sports';

@Pipe({
  name: 'isPreMatchOnly',
  pure: false,
})
export class IsPreMatchOnlyPipe implements PipeTransform {

  transform(match: Match | ScoreStripEntry): boolean {
		if (!match) {
			return true;
		}
		if (match.LiveData && match.LiveData.error) {
			return true;
		}
		const sportCode = match.MatchData.Sport.toUpperCase();
		return !SPORTS.some(s => s.code === sportCode && s.hasLiveMatchPredictions);
  }

}
