<div class="bg-white pt-2 pb-2 pl-2 shadow">
    <div class="flex justify-center items-center min-h-12" *ngIf="valuePlaysLoading">
        <div role="status">
            <svg aria-hidden="true"
                class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor" />
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <div class="flex justify-center items-center min-h-12" *ngIf="valuePlaysError">

    </div>
    <div class="flex items-center gap-2" *ngIf="!valuePlaysLoading && !valuePlaysError">
                <div class="text-xs font-medium px-2 py-2 flex flex-col">
            <span class="inline-block">
                Today's 
            </span>
            <span class="inline-block whitespace-nowrap">
                Best Bets
            </span>
        </div>
        <div class="w-[70%] flex-auto promos-tabs relative">

            <swiper-container space-between="8" slides-per-view="auto" class="bet-intent-widgets" #swiperContainer>
                <swiper-slide *ngFor="let play of displayedValuePlays; index as i; trackBy: trackByFn" class="m-0.5">
                    <div class="promos-tab shadow" [class]="'bet' + i" [class.active]="betTab === ('bet'+ i)"
                        *ngIf="i < freeLimit || (proUser$ | async) === true" (click)="this.toggleShow('bet'+ i, play, false, false, i)">
                        <app-team-icon
                            *ngIf="(play.type === 'h2h' || play.type === 'line') && (play.match.MatchData.Sport | lowercase) !== 'ten'"
                            class="team-logo" alt="" [sportCode]="play.match.MatchData.Sport"
                            [team]="play.bet === 'home' ? play.match.MatchData.HomeTeam : play.match.MatchData.AwayTeam">
                        </app-team-icon>
                        <app-flag-icon
                            *ngIf="(play.type === 'h2h' || play.type === 'line') && (play.match.MatchData.Sport | lowercase) === 'ten'"
                            class="team-logo shadowed" alt="" [countryCode]="play.bet === 'home' ? play.match.MatchData.PlayerData.player1.country
                            : play.match.MatchData.PlayerData.player2.country">
                        </app-flag-icon>
                        <div class="total-icon-ctr" *ngIf="play.type === 'total'">
                            <fa-icon class="total-icon  text-highlight-100"
                                [icon]="play.bet === 'over' ? faChevronsUp : faChevronsDown"></fa-icon>
                        </div>
                        <label
                            class="index-numder -mr-1 -mt-1 bg-AIDimers-grey rounded p-0.5 absolute top-0 right-0 text-[10px] font-normal leading-3 text-darkPrimary-200">
                            #{{ i +1 }}
                        </label>
                    </div>
                    <div class="shadow rounded-full relative h-10 w-10 flex items-center justify-center bg-white promos-tab"
                        [class]="'bet' + i" [class.active]="betTab === ('bet'+ i)"
                        *ngIf="(proUser$ | async) === false && i >= freeLimit"
                        (click)="this.toggleShow('bet'+ i, play, false, true, i)">
                        <app-team-icon-pair *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'"
                            [backSportCode]="play.match.MatchData.Sport" [backTeam]="play.match.MatchData.AwayTeam"
                            [frontSportCode]="play.match.MatchData.Sport" [frontTeam]="play.match.MatchData.HomeTeam">
                        </app-team-icon-pair>
                        <app-flag-icon-pair *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'"
                            [backCountryCode]="play.match.MatchData.PlayerData.player1.country"
                            [frontCountryCode]="play.match.MatchData.PlayerData.player2.country">
                        </app-flag-icon-pair>
                        <label
                            class="index-numder -mr-1 -mt-1 bg-AIDimers-grey rounded p-0.5 absolute top-0 right-0 text-[10px] font-normal leading-3 text-darkPrimary-200">
                            #{{i + 1}}
                        </label>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div routerLink="/best-bets" (click)="this.closePopUpOffer()">
                        <button class="bg-highlight-100 flex items-center gap-2 text-sm py-2 px-4 font-semibold cursor-pointer text-center text-white rounded-full w-[140px]">
                            <img alt="icon" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-6 w-6">
                            <span *ngIf="totalBets <= 0">
                                Best Bets
                            </span>
                            <span *ngIf="totalBets > 0">
                                +{{totalBets}} BETS 
                            </span>
                        </button>
                    </div>
                </swiper-slide>
            </swiper-container>
        </div>
    </div>
</div>

<div class="modal-overlay fixed w-full h-full top-0 left-0 z-[9999] no-doc-scroll"
    *ngIf="matchInfoVisible && selectedData">
    <div class="backdrop-blur-sm bg-black/50 w-full h-full" (click)="closePopUpOffer()">
    </div>
    <div
        class="modal-dialog max-h-screen w-full bottom-0 left-1/2 -translate-x-1/2 promos-items absolute flex flex-col rounded-lg p-6 pt-7 bg-white overflow-auto">
        <!-- <ng-container *ngFor="let play of displayedValuePlays; index as i"> -->

        <div class="flex md:hidden close-button leading-none top-1 right-1 cursor-pointer absolute h-[28px] min-w-[28px] bg-black/60 rounded-full items-center justify-center"
            (click)="closePopUpOffer()">
            <fa-icon class="text-white text-sm" [icon]="faTimes" aria-label="Close"></fa-icon>
        </div>
        <div class="bg-white rounded-t-lg h-full w-full flex flex-col gap-4 promos-item">
            <div class="flex items-center justify-between">
                <div class="text-sm font-semibold text-gray-900">
                    Today's Best Bets
                </div>
                <div class="text-xs text-gray-600">
                </div>
            </div>
            <div class="promos-tabs relative">
                <swiper-container space-between="8" slides-per-view="auto" class="mySwiper" #swiperContainer1>
                    <swiper-slide *ngFor="let play of displayedValuePlays; index as i; trackBy: trackByFn"
                        class="m-0.5">
                        <div class="promos-tab shadow" [class]="'bet' + i" [class.active]="betTab === ('bet'+ i)"
                            *ngIf="i < freeLimit || (proUser$ | async) === true"
                            (click)="this.toggleShow('bet'+ i, play, true, false, i)">
                            <app-team-icon
                                *ngIf="(play.type === 'h2h' || play.type === 'line') && (play.match.MatchData.Sport | lowercase) !== 'ten'"
                                class="team-logo" alt="" [sportCode]="play.match.MatchData.Sport"
                                [team]="play.bet === 'home' ? play.match.MatchData.HomeTeam : play.match.MatchData.AwayTeam">
                            </app-team-icon>
                            <app-flag-icon
                                *ngIf="(play.type === 'h2h' || play.type === 'line') && (play.match.MatchData.Sport | lowercase) === 'ten'"
                                class="team-logo shadowed" alt="" [countryCode]="play.bet === 'home' ? play.match.MatchData.PlayerData.player1.country
                                : play.match.MatchData.PlayerData.player2.country">
                            </app-flag-icon>
                            <div class="total-icon-ctr" *ngIf="play.type === 'total'">
                                <fa-icon class="total-icon  text-highlight-100"
                                    [icon]="play.bet === 'over' ? faChevronsUp : faChevronsDown"></fa-icon>
                            </div>
                            <label
                                class="index-numder -mr-1 -mt-1 bg-AIDimers-grey rounded p-0.5 absolute top-0 right-0 text-[10px] font-normal leading-3 text-darkPrimary-200">
                                #{{ i +1 }}
                            </label>
                        </div>
                        <div class="shadow rounded-full relative h-10 w-10 flex items-center justify-center bg-white promos-tab"
                            [class]="'bet' + i" [class.active]="betTab === ('bet'+ i)"
                            *ngIf="(proUser$ | async) === false && i >= freeLimit"
                            (click)="this.toggleShow('bet'+ i, play, true, true, i)">
                            <app-team-icon-pair *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'"
                                [backSportCode]="play.match.MatchData.Sport" [backTeam]="play.match.MatchData.AwayTeam"
                                [frontSportCode]="play.match.MatchData.Sport"
                                [frontTeam]="play.match.MatchData.HomeTeam">
                            </app-team-icon-pair>
                            <app-flag-icon-pair *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'"
                                [backCountryCode]="play.match.MatchData.PlayerData.player1.country"
                                [frontCountryCode]="play.match.MatchData.PlayerData.player2.country">
                            </app-flag-icon-pair>
                            <label
                                class="index-numder -mr-1 -mt-1 bg-AIDimers-grey rounded p-0.5 absolute top-0 right-0 text-[10px] font-normal leading-3 text-darkPrimary-200">
                                #{{i + 1}}
                            </label>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div routerLink="/best-bets" (click)="this.closePopUpOffer()">
                            <button
                                class="bg-highlight-100 flex items-center gap-2 text-sm py-2 px-4 font-semibold cursor-pointer text-center text-white rounded-full w-[140px]">
                                <img alt="icon" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                                    class="h-6 w-6">
                                <span *ngIf="totalBets <= 0">
                                    Best Bets
                                </span>
                                <span *ngIf="totalBets > 0">
                                    +{{totalBets}} BETS
                                </span>
                            </button>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </div>
            <div class="shadow shadow-slate-300 rounded flex flex-col gap-4 p-4">
                <div class="text-base font-semibold text-gray-900 border-b pb-2">
                    Upcoming Best Bet In {{ displayedValuePlays[currentActiveSilder].match.MatchData.Sport }}
                </div>
                <div class="flex justify-between items-center">
                    <div class="text-xs text-gray-900 font-semibold">
                        {{ matchService.matchStartCountdown(selectedData.match, true) }}
                    </div>
                    <div class="text-xs text-gray-900 font-semibold">
                        <span class="team-name" *ngIf="(selectedData.match.MatchData.Sport | lowercase) !== 'ten'"> {{
                            selectedData.match.MatchData.AwayTeam.Abv }}</span>
                        <span class="team-name" *ngIf="(selectedData.match.MatchData.Sport | lowercase) === 'ten'"> {{
                            matchService.minUniqueName(selectedData.match, 1, true) }}</span>
                        <span> vs. </span>
                        <span class="team-name" *ngIf="(selectedData.match.MatchData.Sport | lowercase) !== 'ten'">{{
                            selectedData.match.MatchData.HomeTeam.Abv }}</span>
                        <span class="team-name" *ngIf="(selectedData.match.MatchData.Sport | lowercase) === 'ten'"> {{
                            matchService.minUniqueName(selectedData.match, 2, true) }}</span>

                    </div>
                </div>
                <div class="rounded-lg bg-slate-200 flex flex-col items-center p-5 gap-5">
                    <div class="team-info flex gap-2 items-center">
                        <div class="team-column w-14 h-14"
                            *ngIf="(selectedData.match.MatchData.Sport | lowercase) !== 'ten'">
                            <app-team-icon class="team-logo" alt="" [sportCode]="selectedData.match.MatchData.Sport"
                                [team]="selectedData.match.MatchData.AwayTeam"></app-team-icon>
                        </div>

                        <div class="team-column w-14 h-14"
                            *ngIf="(selectedData.match.MatchData.Sport | lowercase) === 'ten'">
                            <app-flag-icon class="team-logo shadowed"
                                [countryCode]="selectedData.match.MatchData.PlayerData.player1.country"></app-flag-icon>
                        </div>
                        <div>
                            vs
                        </div>

                        <div class="team-column w-14 h-14"
                            *ngIf="(selectedData.match.MatchData.Sport | lowercase) !== 'ten'">
                            <app-team-icon class="team-logo" alt="" [sportCode]="selectedData.match.MatchData.Sport"
                                [team]="selectedData.match.MatchData.HomeTeam"></app-team-icon>
                        </div>

                        <div class="team-column w-14 h-14"
                            *ngIf="(selectedData.match.MatchData.Sport | lowercase) === 'ten'">
                            <app-flag-icon class="team-logo shadowed"
                                [countryCode]="selectedData.match.MatchData.PlayerData.player2.country"></app-flag-icon>
                        </div>
                    </div>
                    <div class="play-info font-base text-gray-900 font-semibold" *ngIf="!limitedInfo">
                        <span *ngIf="selectedData.type === 'line'">
                            <span *ngIf="(selectedData.match.MatchData.Sport | lowercase) === 'ten'">
                                {{ matchService.minUniqueName(selectedData.match, selectedData.bet === 'home' ? 1 : 2,
                                true) }}
                            </span>
                            <span *ngIf="(selectedData.match.MatchData.Sport | lowercase) !== 'ten'">
                                {{ generalService.teamNameDisplay(selectedData.bet === 'home' ?
                                selectedData.match.MatchData.HomeTeam :
                                selectedData.match.MatchData.AwayTeam) }}
                            </span>
                            {{ selectedData.lineValue >= 0 ? '+' : '' }}{{ selectedData.lineValue | number: '1.1-1' }}
                        </span>

                        <span *ngIf="selectedData.type === 'total'">
                            {{ selectedData.bet === 'over' ? 'Over' : 'Under' }}
                            {{ selectedData.markValue }}
                        </span>

                        <span *ngIf="selectedData.type === 'h2h'">
                            <span *ngIf="(selectedData.match.MatchData.Sport | lowercase) === 'ten'">
                                {{ matchService.minUniqueName(selectedData.match, selectedData.bet === 'home' ? 1 : 2,
                                true) }}
                            </span>
                            <span *ngIf="(selectedData.match.MatchData.Sport | lowercase) !== 'ten'">
                                {{ generalService.teamNameDisplay(selectedData.bet === 'home' ?
                                selectedData.match.MatchData.HomeTeam :
                                selectedData.match.MatchData.AwayTeam) }}
                            </span>
                            win
                        </span>

                        <span *ngIf="selectedData.type === 'firstset'">
                            {{ matchService.minUniqueName(selectedData.match, selectedData.bet === 'home' ? 1 : 2, true)
                            }}
                            win first set
                        </span>
                    </div>
                    <div class="play-info font-base text-gray-900 font-semibold" *ngIf="limitedInfo">
                        <span *ngIf="(selectedData.match.MatchData.Sport | lowercase) !== 'ten'">
                            {{ generalService.teamNameDisplay(selectedData.match.MatchData.AwayTeam) }}
                            vs.
                            {{ generalService.teamNameDisplay(selectedData.match.MatchData.HomeTeam) }} bet
                        </span>
                        <span *ngIf="(selectedData.match.MatchData.Sport | lowercase) === 'ten'">
                            {{ matchService.minUniqueName(selectedData.match, 1, true) }}
                            vs.
                            {{ matchService.minUniqueName(selectedData.match, 2, true) }} bet
                        </span>
                    </div>
                    <div class="edge-info flex items-center gap-2 text-base text-gray font-semibold"
                        *ngIf="!limitedInfo">
                        <div>
                            <span><strong>{{ selectedData.edge | number: '1.1-1' }}%</strong></span>
                            <span class="edge-title ml-2">Edge</span>
                        </div>
                        <!-- <fa-icon [icon]="faCircleInfo" class="text-lg flex"></fa-icon> -->

                        <div class="value-indi" [style.opacity]="betService.valueFireOpacity(selectedData.edge)">
                            <div class="value-icon" aria-label="Value Identified" role="note"></div>
                        </div>
                    </div>
                    <div class="edge-info" *ngIf="limitedInfo">
                        <div class="flex justify-center items-center gap-2 text-base font-semibold">
                            <div>
                                <span><strong>X.X%</strong></span>
                                <span class="edge-title ml-2">Edge</span>
                            </div>
                            <!-- <fa-icon [icon]="faCircleInfo" class="text-lg flex"></fa-icon> -->
                        </div>
                    </div>
                </div>

                <div *ngIf="!limitedInfo">
                    <div class="flex items-center justify-center gap-2 text-center text-sm text-gray-900 font-semibold"
                        *ngIf="(proUser$ | async) === false && (authHelper.userRetrievalDone && !authHelper.user)">
                        <!-- <img src="https://imgix.cosmicjs.com/e87d0d90-df63-11ee-a698-6f33e3e4a628-Dimers-Round-Logo-1.svg" class="h-6 w-6" alt="dimers basic icon"> -->
                        You’re seeing this bet for free
                    </div>
                    <div class="flex items-center justify-center gap-2 text-center text-sm text-gray-900 font-semibold"
                        *ngIf="(proUser$ | async) === false && (authHelper.userRetrievalDone && authHelper.user)">
                        <!-- <img src="https://imgix.cosmicjs.com/e87d0d90-df63-11ee-a698-6f33e3e4a628-Dimers-Round-Logo-1.svg" class="h-6 w-6" alt="dimers basic icon"> -->
                        You're seeing this as a Basic user
                    </div>
                    <div class="flex items-center justify-center gap-2 text-center text-sm text-gray-900 font-semibold"
                        *ngIf="(proUser$ | async) === true">
                        <!-- <img src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-6 w-6" alt="dimers pro icon"> -->
                        You’re seeing this as a Pro user
                    </div>
                </div>
                <div *ngIf="limitedInfo" class="text-gray-900 text-sm font-semibold text-center">
                    You'll unlock this bet with Dimers Pro
                </div>

            </div>

            <div class="bet-btns-group" *ngIf="!limitedInfo">
                <div class="odds-info w-1/2">
                    <div class="odds-btn w-full" (click)="this.oddsButtonClick(selectedData)">
                        <img [src]="selectedData.bookmaker | bookIcon" class="book-icon rounded" width="24" height="24">
                        <div class="odds">
                            {{ betService.formatOdds(selectedData.odds) | async }}
                        </div>
                        <fa-icon class="" [icon]="faArrowUpRightFromSquare"></fa-icon>

                    </div>
                </div>

                <div *ngIf="displayedValuePlays[currentActiveSilder+1]" class="see-next-button w-1/2"
                    (click)="this.nextBet()">
                    See Next Best Bet
                </div>
            </div>
            <div *ngIf="(proUser$ | async) === false && limitedInfo">
                <button class="pro-button w-full" (click)="openProModal()">
                    <img alt="icon" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-6 w-6">
                    Get Dimers Pro </button>
            </div>
        </div>
    </div>
</div>