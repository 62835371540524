import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { MetaSetterService } from 'src/app/general/meta-setter.service';
import { DOCUMENT, isPlatformBrowser, ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SPORTS } from 'src/app/sports';

import { faCircleCheck, faPhone, faTriangleExclamation, faCircleInfo, faCircleDollar, faStar as faStarSolid, faStarHalfStroke, faArrowUp, faSortDown, faSortUp,faChevronDown, faChevronUp} from '@fortawesome/pro-solid-svg-icons';
import { faClipboardCheck, faCircleExclamation, faStar as faStarRegular, faAngleDown, faAngleUp, faCloudArrowUp, faLockKeyhole, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpRight, faThumbsUp, faThumbsDown  } from '@fortawesome/pro-light-svg-icons';

import { BlogService } from 'src/app/blog.service';
import { LocalisationService } from 'src/app/localisation.service';
import { mergeMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-cosmic-sportsbooks',
  templateUrl: './cosmic-sportsbooks.component.html',
  styleUrls: ['./cosmic-sportsbooks.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CosmicSportsbooksComponent implements OnInit, AfterViewInit {

    navTab = "best-sportsbooks";
    browserMode = false;
    stateCode = "";
    state = "";
    windowScrolled = false;

    bestBooks: Array<Record<string, any>>;
    bestBookPromos:any;
    bestBooksLoading = true;
    bestBooksError = false;
    bestBookTab= "";
    valueBetsSports: Array<string> = [];

    faCircleExclamation = faCircleExclamation;
    faClipboardCheck = faClipboardCheck;
    faCircleCheck = faCircleCheck;
    faPhone = faPhone;
    faTriangleExclamation =faTriangleExclamation; 
    faStarFull = faStarSolid;
    faStarHalf = faStarHalfStroke;
    faStarEmpty = faStarRegular;
    faArrowUp = faArrowUp;
    faCircleDollar = faCircleDollar;
    faAngleDown= faAngleDown;
    faAngleUp = faAngleUp;
    faSortDown = faSortDown;
    faSortUp = faSortUp;
    faArrowUpRight = faArrowUpRight;
    faCloudArrowUp = faCloudArrowUp
    faLockKeyhole = faLockKeyhole;
    faCheck = faCheck;
    faThumbsUp =faThumbsUp;
    faThumbsDown = faThumbsDown;
    faChevronDown = faChevronDown;
    faChevronUp = faChevronUp;

    loading:boolean = true;
    loadingError:boolean = false;
    content:any = null;
    slug:string;
    navigationMenu: boolean[] = [true,true,true];
    objectKeys = Object.keys;
    sportsbooks: any = {};
    sportsbookTab: string = "";
    terminologiesTab: string = "";
    heroSportsbooks = [];
    likeButtonClicked: boolean = false;
    dislikeButtonClicked: boolean = false;
    stateLegal: boolean = true;
    notLegalStateHtml:any;
    faCircleinfo = faCircleInfo;
    offerDropdownOpen: boolean = false;
    hrefLang:any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public authHelper: AuthHelperService,
        private metaSetter: MetaSetterService,
        private blogService: BlogService,
        private localisationService: LocalisationService,
        private viewportScroller: ViewportScroller,
        @Inject(PLATFORM_ID) platformId: string,
        @Inject(DOCUMENT) public document: Document,
    ) {
        if (this.route.snapshot.paramMap.has("state")) {
            this.stateCode = this.route.snapshot.paramMap.get("state").toLowerCase().replace(/\./g,'');
            this.state = this.stateCode.toLowerCase().replace(/-/g, ' ');
        }
        if(this.router.url.includes('ontario')){
            this.stateCode = 'ontario';
            this.state = 'ontario';
        }
        this.browserMode = isPlatformBrowser(platformId);
    }

    ngOnInit() {
        this.retrieveDataFromCosmic();
        if (this.browserMode) {
            this.activateValueBetsBlock();
        }
    }

    ngAfterViewInit() {
        this.route.params.subscribe((data) => {
			if (data.state && this.stateCode !== data.state) {
				this.stateCode = data.state.toLowerCase().replace(/\./g,'');
                this.state = this.stateCode.toLowerCase().replace(/-/g, ' ');
                this.retrieveDataFromCosmic();
			}
		});
    }

    @HostListener('window:scroll', [])
    onScroll() {
        this.windowScrolled = window.scrollY !== 0;
    }


    retrieveDataFromCosmic() {
        this.loading = true;
        this.loadingError = false;
        this.blogService.getStateId(this.stateCode).pipe(
            mergeMap(stateId => {
                const api1$ = this.blogService.getCosmicOffersByStateId(stateId, "Best Offers");
                const api2$ = this.blogService.getBestBooksById(stateId, this.stateCode);
                return forkJoin([api1$, api2$]);
            })
        ).subscribe(([promos, pageContent]) => {
            this.loading = false;
            this.content = pageContent.metadata;
            if (pageContent.content) {
                this.stateLegal = false;
                this.notLegalStateHtml = pageContent.content;
            }
            else {
                this.stateLegal = true;
            }

            if (this.content?.sportsbooks) {
                this.sportsbooks = (this.content.sportsbooks).reduce((obj, item) => ({ ...obj, [item.slug]: item }), {});
                this.sportsbookTab = Object.keys(this.content?.choosing_the_right_sportsbook)[0];
            }

            if (this.content?.resources?.terminologies[0]?.title) { this.terminologiesTab = this.content?.resources?.terminologies[0]?.title; }

            if(this.stateCode === 'ontario')
                this.hrefLang = {         
                    "htmlLang": "en-CA",
                    "alternate": [
                        {'en-CA': `https://www.dimers.com/ca/betting/ontario`}
                    ]}
            
            this.metaSetter.setMeta({
                title: this.content?.seo_title,
                seoTitle: this.content?.seo_title,
                description: this.content?.meta_description,
                canonicalURL: `https://www.dimers.com/best-books/${this.stateCode}`,
                hrefLang: this.hrefLang
            });
            // promos = promos.filter(item => item.metadata.placement_locations.includes('Best Offers'));

            //sorting offers based on sportsbook index field
            if(promos) {
                promos.sort((a, b) => {
                    return a.metadata.sportsbook[0].metadata.index - b.metadata.sportsbook[0].metadata.index
                });
                //grouping offers based on sportsbook
                this.heroSportsbooks = this.groupBy(promos);
                this.bestBookTab = Object.keys(this.heroSportsbooks)[0];
            }

        }, ()=>{
            this.loadingError = true;
        });
        
    }

    //sorting offers based on sportsbook index field
    sortingOffers = (a, b) => {
        return a.value[0].metadata.sportsbook[0].metadata.index > b.value[0].metadata.sportsbook[0].metadata.index ? 1 : -1;
    }

    groupBy(xs:any) {
        return xs.reduce((rv, x) => {
            (rv[x.metadata.sportsbook[0].title] = rv[x.metadata.sportsbook[0].title] || []).push(x);
            return rv;
        }, {});
    };

    toggleOpen(index: number) {
        this.navigationMenu[index] = !this.navigationMenu[index];
    }


    jumpToSection(tabMenu:string): void {
    this.navTab = tabMenu;
        this.viewportScroller.scrollToAnchor(tabMenu);
    }

    scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    toggleShow(bestBook: string) {
        this.bestBookTab = bestBook;
    }
  
    toggleSportsbook(sportsbook: string) {
        this.sportsbookTab = sportsbook;
    }
    toggleTerminologies(tab:string) {
        this.terminologiesTab = tab;
    }

    // redirectBookiePromoLink(url: string, queryParams: Record<string, string>, bookmaker: any) {
    //     if (!this.excludeBookies(bookmaker?.slug || "")) {
    //         queryParams.c = (this.router.url.split("/")[1] || 'home') + (
    //             this.authHelper.user
    //                 ? ('|' + this.authHelper.user.id)
    //                 : '|visitor'
    //         );
    //     }

    //     const combinedUrl = url + "?" + new URLSearchParams(queryParams).toString();
    //     // console.log(combinedUrl);
    //     if (this.browserMode) {
    //         const gtmTag = {
    //             event: "exit",
    //             event_category: "reviews",
    //             event_label: bookmaker.slug,
    //         };

    //         this.gtmService.pushTag(gtmTag)
    //             .then(() => {
    //                 window.open(combinedUrl, "_self");
    //             })
    //             .catch(e => {
    //                 console.error("Google Tag Manager Script failed: " + e)
    //                 window.open(combinedUrl, "_self");
    //             });
    //     }
    // }

    // excludeBookies(bookie: string): boolean {
    //     bookie = bookie.replace(/\s/g, '').toLowerCase()
    //     switch (bookie) {
    //     case 'bet365':
    //     case 'bet_365':
    //         return true
    //     case 'pointsbet':
    //         return true
    //     case 'betmgm':
    //         return true
    //     default:
    //         return false
    //     }
    // }

    activateValueBetsBlock(): void {
        if (typeof window !== "undefined" && typeof document !== "undefined") {
            this.valueBetsSports = this.localisationService.sportPrioritySort(SPORTS).filter(s => s.valueSummaryEligible).map(s => s.code);
        }
    }

    // remove smartlook functionality
    // likeButtonClick(value:string): void {
    //     console.log(value + "buttons clicked");
    //     const eventName = 'ArticleIsUseful';
    //     let ArticleIsUseful = {
    //     "type": value,
    //     "page-path": this.document?.location.pathname 
    //     };
    //     window.smartlook('track', eventName, ArticleIsUseful);
    // }

    toggleOfferDropdown(event: Event): void {
        event.stopPropagation();
        this.offerDropdownOpen = !this.offerDropdownOpen;
    }
}
