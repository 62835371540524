<h2 class="heading">
    {{data.title}}
</h2>
<div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-12 mt-8">
    <div class="border border-highlight-100 rounded-lg p-6 flex flex-col gap-4 justify-end items-center relative bg-navy-50" *ngFor="let item of data.items">
        <div class="flex justify-center items-center w-12 h-12 text-xl text-white bg-highlight-100 border-highlight-100 rounded-md absolute top-0 -mt-[24px]">
            <img [src]="item.image.url" alt="" width="24" height="24">
        </div>
        <div class="text-lg text-gray-900 font-semibold mt-4">
            {{item.title}}
        </div>
        <div [innerHTML]="item.content | trustCosmic" class="text text-center">
        </div>   
    </div>
</div>