import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bookForegroundColour'
})
export class BookForegroundColourPipe implements PipeTransform {

  transform(bookName: string): string {
    switch (bookName?.toLowerCase()) {
      case "betstreet":
        return "white";
      case "fanduel":
        return "white";
      case "sugarhouse":
        return "white";
      case "pointsbet":
        return "white";
      case "william_hill":
        return "white";
      case "draftkings":
        return "white";
      case "wynn":
        return "white";
      case "betmgm":
        return "black";
      case "bet_rivers_in":
      case "bet_rivers_va":
      case "bet_rivers_co":
        return "#18355e";
      case "bet365":
      case "bet_365":
        return "white";
      case "betway":
        return "white";
      case "unibet":
        return "white";
      case "tipico":
        return "#fdfcfc";
      case "twinspires":
        return "white";
      default:
        return "initial"
    }
  }
}
