import { DOCUMENT } from '@angular/common';
import { Component, AfterViewInit, ElementRef, ViewChild, OnInit, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { faTwitter, faFacebook, faInstagram, faYoutube/*, faDiscord,*/ } from '@fortawesome/free-brands-svg-icons';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { BlogService } from 'src/app/blog.service';
import { CookieService } from "ngx-cookie";

@Component({
	selector: 'app-site-footer',
	templateUrl: './site-footer.component.html',
	styleUrls: ['./site-footer.component.scss'],
})
export class SiteFooterComponent implements OnInit, AfterViewInit {
	@ViewChild('formContainer') containerElement: ElementRef;
	@ViewChild('formContainerMobile') containerElementMobile: ElementRef;

	@Input() fireNewsletterPopUp: () => void;
	@Input() showPartner: boolean;

	state: string;

	faTwitter = faTwitter;
	faFacebook = faFacebook;
	faInstagram = faInstagram;
	faYoutube = faYoutube;
	// faDiscord = faDiscord;

	formSending = false;
	formSent = false;
	formError = false;
	formValid = false;

	constructor(
        private blogService: BlogService,
        private router: Router,
		@Inject(DOCUMENT) public document: Document,
		public authHelper: AuthHelperService,
        private cookieService: CookieService,
        
        ) 
    {}

	email = '';

	
	ngOnInit(): void {
		if (typeof document !== "undefined") {
			this.blogService.userLocationData$
			.subscribe(loc => {
				if (loc?.state_name) {
					this.state = loc.state_name.toLowerCase();
				} else {
					// default to New Jersey if user location data can't be retrieved or user is outside of United States
					this.state = "new jersey";
				}
			})
		}
	}

	ngAfterViewInit(): void {
		if (typeof document !== "undefined") {
			// Desktop version of form
			// if (
			// 	Array.from(this.containerElement.nativeElement.children).some(
			// 		(s) =>
			// 			s instanceof HTMLScriptElement &&
			// 			s.src ===
			// 				`https://ams.ciphersports.io/form/generate.js?id=17`
			// 	)
			// ) {
			// 	const oldScript: HTMLScriptElement = Array.from(
			// 		this.containerElement.nativeElement.children
			// 	).find(
			// 		(s) =>
			// 			s instanceof HTMLScriptElement &&
			// 			s.src ===
			// 				`https://ams.ciphersports.io/form/generate.js?id=17`
			// 	) as HTMLScriptElement;
			// 	oldScript.remove();
			// }
	
			// let js;
			// js = document.createElement('script');
			// js.defer = "true";
			// js.src = `https://ams.ciphersports.io/form/generate.js?id=17`;
			// (this.containerElement.nativeElement as HTMLElement).appendChild(js);

			// // Mobile version of form

			// if (
			// 	Array.from(this.containerElement.nativeElement.children).some(
			// 		(s) =>
			// 			s instanceof HTMLScriptElement &&
			// 			s.src ===
			// 				`https://ams.ciphersports.io/form/generate.js?id=23`
			// 	)
			// ) {
			// 	const oldScript: HTMLScriptElement = Array.from(
			// 		this.containerElement.nativeElement.children
			// 	).find(
			// 		(s) =>
			// 			s instanceof HTMLScriptElement &&
			// 			s.src ===
			// 				`https://ams.ciphersports.io/form/generate.js?id=23`
			// 	) as HTMLScriptElement;
			// 	oldScript.remove();
			// }
	
			// let jsMobile;
			// jsMobile = document.createElement('script');
			// js.defer = "true";
			// jsMobile.src = `https://ams.ciphersports.io/form/generate.js?id=23`;
			// (this.containerElementMobile.nativeElement as HTMLElement).appendChild(
			// 	jsMobile
			// );
		}
	}
    
	sendEmail(): void {
		if (this.formValid) {
			this.formSending = true;
			this.formSent = false;
			this.formError = false;

			this.blogService.addNewsletterEmail(this.email).subscribe(
				(_) => {
					this.formSent = true;
					this.formSending = false;
				},
				() => {
					this.formError = true;
					this.formSending = false;
				}
			);
		}
	}

	verifyForm(): void {
		if (!this.email) {
			this.formValid = false;
		} else if (!/.+@.+\..+/.test(this.email)) {
			this.formValid = false;
		} else {
			this.formValid = true;
		}
	}
}