import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheck, faStar as faStarRegular, faSquare, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown, faChevronUp, faStar as faStarSolid, faCheckSquare, faSliders, faArrowsUpDown } from "@fortawesome/pro-solid-svg-icons";
import { Observable, map, shareReplay } from 'rxjs';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { LocalisationService } from 'src/app/localisation.service';

@Component({
  selector: 'app-best-bet-mobile-filter',
  templateUrl: './best-bet-mobile-filter.component.html',
  styleUrls: ['./best-bet-mobile-filter.component.scss']
})
export class BestBetMobileFilterComponent {
	sportsDropdownLabel: string = 'Default';
	betTypesDropdownLabel: string = 'Default';
	@Input() filterSports: Array<string>;
	@Input() valueBetsSports: Array<string>;
	@Input() filterBetTypes: Array<string>;
	@Input() displayedValuePlays: number;
	@Input() filterTimeframe: number;
	@Input() setFilterTimeframe: (timeframe: number) => void;
	@Input() toggleFilterBetType: (type: string) => void;
	@Input() toggleAllFilterBetType: () => void;
	@Input() toggleFilterSport: (sport: string) => void;
	@Input() toggleAllFilterSports: () => void;
	@Input() mobileFilterOpen: any;
	@Output() mobileFilterOpenChange = new EventEmitter<any>();
	@Output() filterSportsChange = new EventEmitter<string[]>();
	@Output() filterBetTypesChange = new EventEmitter<string[]>();

	proUser$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
		map((detailedUser) => {
			return !!detailedUser?.app_metadata?.dimers_pro;
		}),
		shareReplay(1)
	);
	sportsToggle: boolean = false;
	betTypesToggle: boolean = false;
	betTypes: string[] = ['line', 'total', 'h2h', 'other'];
	sortToggle: boolean = false;

	faChevronUp = faChevronUp;
	faChevronDown = faChevronDown;
	faCheck = faCheck;
	faStarSolid = faStarSolid;
	faStarRegular = faStarRegular;
	faTimes = faTimes;
	faSquare = faSquare;
	faCheckSquare = faCheckSquare;
	faSliders = faSliders;
	faArrowsUpDown = faArrowsUpDown;

	constructor(
		public localisationService: LocalisationService,
		private authHelper: AuthHelperService,
	) {
	}

	closeMobileFilter() {
		this.mobileFilterOpenChange.emit(false);
	}

	toggleSportsDropdown() {
		this.betTypesToggle = false;
		this.sportsToggle = !this.sportsToggle;
	}

    sportsLabelupdate() {
        this.sportsDropdownLabel = `${
			this.filterSports.length === this.valueBetsSports.length
				? 'All'
				: this.filterSports.length
		} ${this.filterSports.length === 1 ? 'sport' : 'sports'}`;
    }

    betTypesLabelupdate() {
        this.betTypesDropdownLabel = `${
			this.filterBetTypes.length === this.betTypes.length
				? 'All'
				: this.filterBetTypes.length
		} ${this.filterBetTypes.length === 1 ? 'bet type' : 'bet types'}`;
    }

	toggleBetsDropdown() {
		this.sportsToggle = false;
		this.betTypesToggle = !this.betTypesToggle;
	}

	resetFilterOptions() {
		this.filterSports = this.valueBetsSports.slice();
		this.filterSportsChange.emit(this.filterSports);
		this.filterBetTypes = ['line', 'total', 'h2h', 'other'];
		this.filterBetTypesChange.emit(this.filterBetTypes);
		this.setFilterTimeframe(24);
	}

    toggleAllFilterSportsWithDelay(){
        this.toggleAllFilterSports();
        setTimeout(() => {
            this.sportsLabelupdate();
            }, 500);
    }
    toggleAllFilterBetTypeWithDelay() {
        this.toggleAllFilterBetType();
        setTimeout(() => {
            this.betTypesLabelupdate();
            }, 500);
    }
}

