<div class="feedback-form-btn flex gap-2 items-center" (click)="showFeedbackFormPopup()" *ngIf="blogService.feedbackFormVisible && showFeedbackOnscroll && showFeedback">
    <fa-icon [icon]="faMessageLines" class="leave-message-icon"></fa-icon>
    Give feedback
    <fa-icon [icon]="faXmark" (click)="blogService.disableFeedbackForm()" class="mobile-close-btn"></fa-icon>
</div>
<div class="modal no-doc-scroll" *ngIf="formVisible">
    <div class="backdrop-blur-sm bg-black/50 w-full h-full" (click)="closefeedbackForm()">
	</div>
    <div class="modal-content">
        <div *ngIf="!sendSuccessful && !sendError ">
            <div class="title">
                Help us improve Dimers
                <fa-icon [icon]="faTimes" class='close-icon' (click)="closefeedbackForm()"></fa-icon>
            </div>
            <div class="first-step flex flex-col gap-6 mt-6" *ngIf="!topicSubmitted">
                <div>
                    <label for="state" class="block text-sm font-medium leading-6 text-gray-900">What is your feedback about?*</label>
                    <select id="state" name="state" [(ngModel)]="selectedOption"
                        class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-highlight-100 sm:text-sm sm:leading-6">
                        <option value="" selected disabled>Select an option</option>
                        <option *ngFor="let question of questions" value="{{question}}">{{ question }}</option>
                    </select>
                </div>
                <!-- <div>
                    <div class="text-sm leading-6">
                        <label for="contactMe" class="font-medium text-gray-900">Dimers may contact me about this feedback.</label>
                    </div>
                </div> -->
                <button (click)="nextStep()"  class="submit-btn" [disabled]="!this.selectedOption">
                    <span class="button__text">Continue</span>
                </button>
                <div class="text-center text-sm font-medium leading-6 text-gray-900">
                    Looking for help? <a href="subscription/faq">Visit our FAQ section</a>
                </div>
            </div>
            <div class="feedback-form mt-6" *ngIf="topicSubmitted">
                <form [formGroup]="feedbackFormData">
                    <div class="form-group">
                        <label for="email" class="text-sm leading-6 text-gray-900">Email</label>
                        <input id="email" type="email" formControlName="email" class="form-control"/>
                        <div class="error-message"
                            *ngIf="this.feedbackFormData.get('email')?.invalid && (this.feedbackFormData.get('email')?.dirty || this.feedbackFormData.get('email')?.touched)">
                            <div *ngIf="this.feedbackFormData.get('email')?.errors?.['email']">
                                Email must be a valid Email
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="comment" class="text-sm leading-6 text-gray-900">{{commentTitles}}</label>
                        <div class="text-gray-500 text-xs" *ngIf="commentTitles === 'Describe the bug or issue*'">Please provide details where possible e.g. your device, what exactly you were trying to do etc.</div>
                        <textarea id="comment" formControlName="comment" class="form-control h-32" [placeholder]="commentTitles !== 'Describe the bug or issue*' ? 'Loving this page...' : 'I think I have found a bug. Whenever I try to…'">
                        </textarea>
                    </div>

                    <div class="reCaptcha">
                        <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)"  (errored)="onError($event)" errorMode="handled" size="invisible" [badge]="'inline'" [siteKey]="siteKey"></re-captcha>
                    </div>

                    <button (click)="submitCheck(captchaRef)" type="submit" class="submit-btn" [disabled]="!feedbackFormData.valid"  [class.button--loading]="submitLoading">
                        <span class="button__text">Send feedback</span>
                    </button>
                    <div class="disclaimer">
                        By entering your email, you agree to our <a routerLink="/terms-of-service">Terms of Service</a> and <a routerLink="/privacy-policy">Privacy Policy</a>.
                    </div>
                </form>
            </div>
        </div>
        <div *ngIf="sendSuccessful">
            <div class="info-content">
                <div class="title">Thanks for the feedback!</div>
                <ng-container *ngIf="this.selectedOption === 'Suggest an improvement'">
                    <div class="more-info text-base font-medium text-gray-900 leading-6">
                        Vote for feature ideas shared by other Dimers members or add your own to the
                        <a href="https://dimers.supahub.com/" target="_blank">list here</a>.
                    </div>
                </ng-container>
                <button class="close-btn" (click)="closefeedbackForm()">Close</button>
            </div>
        </div>
        <div *ngIf="sendError">
            <div  class="error-info-content">
                <div  class="title">
                    Sorry, something went wrong submitting your feedback. Please try again.
                </div> 
                <div class="btn-group">
                    <button class="close-btn" (click)="onSubmit()">Try again</button>
                    <button class="close-btn" (click)="closefeedbackForm()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>