import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bookIconAlt'
})
export class BookIconAltPipe implements PipeTransform {

  transform(bookName: string): string {
    switch (bookName?.toLowerCase()) {
      case "betstreet":
        return "https://content-blocks-node.azurewebsites.net/static/assets/betstreet-icon-white.png";
      case "fanduel":
        return "https://content-blocks-node.azurewebsites.net/static/assets/fanduel-icon-white.svg";
      case "sugarhouse":
        return "https://content-blocks-node.azurewebsites.net/static/assets/sugarhouse-icon-white.png";
      case "pointsbet":
        return "https://content-blocks-node.azurewebsites.net/static/assets/pointsbet-icon-white.png";
      case "william_hill":
        return "https://content-blocks-node.azurewebsites.net/static/assets/william-hill-icon-white.png";
      case "draftkings":
        return "https://content-blocks-node.azurewebsites.net/static/assets/draftkings-icon-white.svg";
      case "wynn":
        return "https://cdn.ciphersports.io/images/bookmaker-images/wynnbet-icon.png";
      case "betmgm":
        return "https://content-blocks-node.azurewebsites.net/static/assets/betmgm-icon-white.png";
      case "bet_rivers_in":
      case "bet_rivers_va":
      case "bet_rivers_co":
        return "https://content-blocks-node.azurewebsites.net/static/assets/bet-rivers-icon-white.svg";
      case "bet365":
      case "bet_365":
        return "https://content-blocks-node.azurewebsites.net/static/assets/bet365-icon-white.svg";
      case "betway":
        return "https://content-blocks-node.azurewebsites.net/static/assets/betway-icon-white.png";
      case "unibet":
        return "https://content-blocks-node.azurewebsites.net/static/assets/unibet-icon-white.png";
      case "tipico":
        return "https://content-blocks-node.azurewebsites.net/static/assets/tipico-icon-white.png";
      case "twinspires":
        return "https://content-blocks-node.azurewebsites.net/static/assets/twinspires-icon-white.png";
      default:
        return ""
    }
  }

}
